import { gql } from 'urql'

const QueryNotificationsList = gql`
  query ($limit: Int, $page: Int, $unread: Boolean) {
    notificationsList(limit: $limit, page: $page, unread: $unread) {
      collection {
        id
        payload
        user {
          email
          fullName
          defaultColour
          avatarVariants
        }
        recipient {
          email
          fullName
          defaultColour
          avatarVariants
        }
        seenAt
        readAt
        createdAt
        actionType
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryNotificationsList
