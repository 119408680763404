import type { Logger } from 'pino'
import pino from 'pino'
import configuration from '~/configuration'

let logger: Logger

/**
 * @name getLogger
 * @description Get a Pino logger instance
 */
function getLogger() {
  if (logger) {
    return logger
  }

  logger = pino({
    browser: {},
    level: 'debug',
    base: {
      env: configuration.environment
    }
  })

  return logger
}

export default getLogger()
