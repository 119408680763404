import {
  AnyVariables,
  DocumentInput,
  RequestPolicy,
  useQuery,
  UseQueryArgs
} from 'urql'
import {
  destroySessionCookiesClient,
  LIST_KEYS_TENANT_BLOCKED,
  LIST_KEYS_TENANT_EXPIRED
} from '../utilities/catch-api-error'
import configuration from '~/configuration'
import { setSessionCookieClient } from './save-session-cookie'
import {
  SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM,
  SESSION_EXPIRES_AT_COOKIE_NAME
} from '../constants/cookies'
import { parseCookies } from 'nookies'
// eslint-disable-next-line import/no-restricted-paths
import { getUserAuthentication } from '~/lib/next/auth-utilities'

function useQueryGraphQL<
  Data = any,
  Variables extends AnyVariables = AnyVariables
>({
  query,
  variables,
  shouldPause = true,
  requestPolicy = 'network-only'
}: {
  query: DocumentInput<Data, Variables>
  variables?: UseQueryArgs<Variables, Data>['variables']
  shouldPause?: boolean
  requestPolicy?: RequestPolicy | undefined
}) {
  const [result, reexecuteQuery] = useQuery({
    query,
    variables: variables,
    pause: shouldPause,
    requestPolicy
  })

  const { data, fetching, error } = result

  let statusCode = 200
  let parseErrors
  if (error) {
    const { graphQLErrors, networkError } = error
    const cookies = parseCookies()
    if (networkError) {
      console.error('Network Error')
    } else {
      if (graphQLErrors.length === 1) {
        parseErrors = JSON.parse(JSON.stringify(graphQLErrors[0]))
        statusCode =
          parseErrors?.message && typeof parseErrors?.message === 'string'
            ? parseErrors?.extensions?.http_code
            : parseErrors?.message?.extensions?.http_code
        let blockedErrorCode =
          parseErrors?.extensions?.error_code ||
          parseErrors?.message?.extensions?.error_code
        if (
          blockedErrorCode &&
          LIST_KEYS_TENANT_BLOCKED.includes(blockedErrorCode) &&
          [401, 403].includes(statusCode)
        ) {
          setSessionCookieClient(
            SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM,
            JSON.stringify(true).toString(),
            Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
          )
          window.location.href = configuration.path.tenantBlocked
        } else {
          if (
            blockedErrorCode &&
            LIST_KEYS_TENANT_EXPIRED.includes(blockedErrorCode) &&
            [401, 403].includes(statusCode)
          ) {
            const user = getUserAuthentication(cookies)
            const expiredPage = user?.ownTenant
              ? configuration.path.settings.plans_expired
              : configuration.path.errorExpiredPlan
            window.location.href = expiredPage
          } else {
            if (statusCode === 401) {
              destroySessionCookiesClient()
              setTimeout(() => {
                if (window.location.pathname.startsWith('/careerhub/')) {
                  const slug = window.location.pathname.split('/')?.[2]
                  window.location.href =
                    configuration.path.careerHub.login(slug)
                } else {
                  window.location.href = configuration.path.login
                }
              }, 1500)
            }

            if (statusCode === 403) {
              console.error(
                'You do not have permission to access the requested page. Please contact your system administrator for further assistance.'
              )

              if (window.location.pathname.startsWith('/careerhub/')) {
                const slug = window.location.pathname.split('/')?.[2]
                window.location.href =
                  configuration.path.careerHub.errorAccessDenied(slug)
              } else window.location.href = configuration.path.errorAccessDenied
            }

            if (statusCode === 404) {
              if (window.location.pathname.startsWith('/careerhub/')) {
                const slug = window.location.pathname.split('/')?.[2]
                window.location.href =
                  configuration.path.careerHub.error404(slug)
              } else window.location.href = configuration.path.error404
            }

            if (statusCode === 500) {
              console.error('Network Error')
            }
          }
        }
      }
    }
  }

  return {
    trigger: reexecuteQuery,
    isLoading: fetching,
    data,
    error: {
      parseErrors,
      statusCode
    }
  }
}

export default useQueryGraphQL
