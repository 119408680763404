import { PUBLIC_APP_URL } from '~/core/constants/env'
import { CareerPageSettingType } from '../types'

export const getTitleOfMeta = ({
  jobTitle,
  jobLocations,
  companyName
}: {
  jobTitle: string
  jobLocations: string[]
  companyName: string
}) => {
  if (!jobTitle) return ''
  return `Hiring for ${jobTitle} ${
    jobLocations.length > 0 ? `for ${jobLocations.join(' ,')}` : ''
  } ${companyName ? `- ${companyName}` : ''}
  `
}

export const removeHTMLTags = (content: string): string => {
  return content.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '')
}

export const combineDomainCareerPage = (
  careerSetting: CareerPageSettingType
) => {
  const defaultLang = Object.keys(careerSetting?.languages || {}).find(
    (lang) => !!careerSetting.languages?.[lang]?.default
  )

  return `${PUBLIC_APP_URL}${defaultLang !== 'en' ? `/${defaultLang}` : ''}`
}
