import { useEffect, useState } from 'react'
import useMutationGraphQL from '~/core/middleware/use-mutation-graphQL'

export function useSubmitCommon(
  query: string,
  config?: {
    notUpdateLoadingAfterMutation: boolean
  }
) {
  const [isLoading, setLoading] = useState(false)
  const {
    trigger,
    isLoading: isLoadingMutation,
    data,
    error
  } = useMutationGraphQL({
    query
  })

  useEffect(() => {
    if (isLoadingMutation) {
      setLoading(true)
    } else if (
      isLoadingMutation === false &&
      !config?.notUpdateLoadingAfterMutation
    ) {
      setLoading(false)
    }
  }, [isLoadingMutation, config])

  useEffect(() => {
    if (error) {
      setLoading(false)
    }
  }, [error])

  return {
    trigger,
    isLoading,
    data,
    error
  }
}
