const MutationFirebaseDeviceRegistrationCreate = `
  mutation($deviceToken: String!, $deviceKind: DeviceRegistrationKind) {
    firebaseDeviceRegistrationCreateOrUpdate(
      input: {
        deviceToken: $deviceToken,
        deviceKind: $deviceKind
      }
    ) {
      deviceRegistration {
        deviceKind
        deviceToken
        user {
          id
        }
      }
    }
  }
`

export default MutationFirebaseDeviceRegistrationCreate
