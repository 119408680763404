import Head from 'next/head'
import configuration from '~/configuration'

const GoogleTracking = () => {
  if (configuration.production === false) return null

  return (
    <Head>
      <script
        defer
        data-domain="app.hireforce.io"
        src="https://tracking.hireforce.io/js/script.js"
      />
    </Head>
  )
}

export default GoogleTracking
