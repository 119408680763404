import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import configuration from '~/configuration'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import QueryNotificationsUnreadCount from '~/lib/features/notifications/graphql/query-notifications-unread-count'
import useNotificationStore from '~/lib/features/notifications/store'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import useBoundStore from '~/lib/store'
import useSubscriptionPlan from '../Subscription/useSubscriptionPlan'

export const PAGES_NOT_FETCH_API: string[] = [
  configuration.path.authError,
  configuration.path.careers.list,
  configuration.path.contactUs,
  configuration.path.error404,
  configuration.path.error500,
  configuration.path.errorAccessDenied,
  configuration.path.extension.list,
  configuration.path.login,
  configuration.path.termsOfUse,
  configuration.path.register,
  configuration.path.onboarding,
  configuration.path.verifyEmail,
  configuration.path.tenantBlocked,
  configuration.path.privacyPolicy,
  configuration.path.selectCompany,
  configuration.path.errorExpiredPlan,
  configuration.path.settings.plans_expired
]

const NotificationBadgeCount = () => {
  const router = useRouter()
  const { clientGraphQL } = useContextGraphQL()
  const { user, setToast } = useBoundStore()
  const { notificationTotalUnreadCount, setNotificationTotalUnreadCount } =
    useNotificationStore()
  const {
    isFeatureEnabled,
    isUnLockFeature,
    data: dataPlan
  } = useSubscriptionPlan()

  const [isFirstLoading, setFirstLoading] = useState(true)

  useEffect(() => {
    if (
      !PAGES_NOT_FETCH_API.some((url) => router?.pathname.startsWith(url)) &&
      user?.id
    ) {
      if (
        isFeatureEnabled(PLAN_FEATURE_KEYS.notification) &&
        isUnLockFeature(PLAN_FEATURE_KEYS.notification) &&
        isFirstLoading === true
      ) {
        const retrieveNotificationCount = async () => {
          try {
            if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
              // Retrieve the notification permission status
              const permission = await Notification.requestPermission()

              // Check if permission is granted before retrieving the token
              if (permission === 'denied') {
                setInterval(fetchNotificationBadgeCount, 10000)
              }
            }
          } catch (error) {
            console.log('An error occurred while retrieving token:', error)
          }
        }

        setFirstLoading(false)
        fetchNotificationBadgeCount()
        retrieveNotificationCount()
      }
    }
  }, [user, dataPlan])

  const fetchNotificationBadgeCount = useCallback(() => {
    clientGraphQL.query(QueryNotificationsUnreadCount).then(
      (result: {
        error: { graphQLErrors: Array<object> }
        data: {
          notificationsUnreadCount: number
        }
      }) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            setToast
          })
        }

        const { notificationsUnreadCount } = result.data || {}
        const count =
          notificationTotalUnreadCount !== notificationsUnreadCount
            ? notificationsUnreadCount >= 0
              ? notificationsUnreadCount
              : 0
            : 0

        if (notificationsUnreadCount !== notificationTotalUnreadCount) {
          setNotificationTotalUnreadCount(count)
        }

        return
      }
    )
  }, [clientGraphQL, notificationTotalUnreadCount])

  return null
}

export default NotificationBadgeCount
