import { NextRouter } from 'next/router'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import HTMLDisplay from '~/components/HTMLDisplay'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { isHTML } from '~/core/utilities/common'
import {
  INotification,
  INotificationTask
} from '~/lib/features/notifications/types'
import useBoundStore from '~/lib/store'
import NotificationItemLayout from './NotificationItemLayout'

const UserAssignedTask: FC<{
  router: NextRouter
  notification: INotification
  onClick: () => void
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({ router, notification, onClick, onDelete, isLoadingDeleted }) => {
  const { setOpenTaskDrawer } = useBoundStore()
  const propertiesRelated = notification?.payload as INotificationTask

  return (
    <NotificationItemLayout
      onDelete={onDelete}
      isLoadingDeleted={isLoadingDeleted}
      user={notification.user}
      actionType={notification.actionType}
      readAt={notification.readAt}
      createdAt={notification.createdAt}>
      {({ time }) => (
        <>
          <TypographyText
            className="cursor-pointer text-sm text-gray-700"
            onClick={() => {
              setOpenTaskDrawer(true)
              onClick && onClick()
            }}>
            {propertiesRelated?.applicantId ? (
              <Trans
                i18nKey="activity:notification:userAssignedApplicationTask"
                values={{
                  userName: notification?.user?.fullName,
                  profileFullName: propertiesRelated.profileFullName
                }}>
                <span className="font-medium text-gray-900" />
              </Trans>
            ) : propertiesRelated?.companyName ? (
              <Trans
                i18nKey="activity:notification:userAssignedCompanyTask"
                values={{
                  userName: notification?.user?.fullName,
                  companyName: propertiesRelated.companyName
                }}>
                <span className="font-medium text-gray-900" />
              </Trans>
            ) : (
              <Trans
                i18nKey="activity:notification:userAssignedTask"
                values={{
                  userName: notification?.user?.fullName
                }}>
                <span className="font-medium text-gray-900" />
              </Trans>
            )}
            {time}
          </TypographyText>

          {propertiesRelated?.taskTitle ? (
            <div
              className={cn(
                'mt-1.5 rounded border border-gray-100 bg-gray-50 px-1.5 pt-3',
                isHTML(propertiesRelated.taskTitle) ? 'pb-1.5' : 'pb-3'
              )}>
              <HTMLDisplay
                content={propertiesRelated.taskTitle}
                className="line-clamp-2 text-xs text-gray-900"
              />
            </div>
          ) : null}
        </>
      )}
    </NotificationItemLayout>
  )
}

export default UserAssignedTask
