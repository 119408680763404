import { COUNTRIES } from '../core/constants/countries'
import {
  DEFAULT_ASYNC_LOADING_OPTIONS,
  DEFAULT_LOADING_PAGE_SIZE,
  DEFAULT_MAX_PAGE_SIZE,
  DEFAULT_MOUNT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE
} from '../core/constants/enum'
import {
  APP_ENVIRONMENT,
  DEFAULT_LOCALE,
  NODE_ENV,
  PUBLIC_SENTRY_DSN,
  SENTRY_DSN
} from '../core/constants/env'
import { ROLE } from '../core/constants/role'
import { ICONS_SOCIAL } from '../core/constants/social'
import apiConfiguration from './api'
import keyConfiguration from './key'
import pathConfiguration from './path'
import seoConfiguration from './seo'

const configuration = {
  site: {
    locale: DEFAULT_LOCALE
  },
  path: pathConfiguration,
  api: apiConfiguration,
  key: keyConfiguration,
  seo: seoConfiguration,
  environment: APP_ENVIRONMENT,
  production: NODE_ENV === 'production',
  sentry: {
    dsn: SENTRY_DSN || PUBLIC_SENTRY_DSN
  },
  mailto: `support@hireforce.io`,
  appDomain: `Hireforce.io`,
  appUrl: `https://hireforce.io`,
  defaultPageSize: DEFAULT_PAGE_SIZE,
  defaultMaxPageSize: DEFAULT_MAX_PAGE_SIZE,
  defaultTableMountPageSize: DEFAULT_MOUNT_PAGE_SIZE,
  defaultTableLoadingPageSize: DEFAULT_LOADING_PAGE_SIZE,
  defaultAsyncLoadingOptions: DEFAULT_ASYNC_LOADING_OPTIONS,
  countries: COUNTRIES,
  role: ROLE,
  iconSocial: ICONS_SOCIAL,
  downloadExt:
    'https://chromewebstore.google.com/detail/hireforce-sourcing/pbabkjheknegnbnnfmlojibfdcffbfgj'
}

export default configuration
