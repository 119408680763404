import { NextRouter } from 'next/router'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import configuration from '~/configuration'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import { FEEDBACK_OPTIONS } from '~/lib/features/calendar/utilities/enum.cva'
import { FEEDBACK_TAB } from '~/lib/features/candidates/utilities/enum'
import {
  INotification,
  INotificationInterview
} from '~/lib/features/notifications/types'
import NotificationItemLayout from './NotificationItemLayout'

const UserSubmitInterviewFeedback: FC<{
  router: NextRouter
  notification: INotification
  onClick: () => void
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({ router, notification, onClick, onDelete, isLoadingDeleted }) => {
  const { t, i18n } = useTranslation()
  const interviewEvents = useEnumsData({
    enumType: 'InterviewEvent',
    locale: i18n.language
  })
  const propertiesRelated = notification?.payload as INotificationInterview

  return (
    <NotificationItemLayout
      onDelete={onDelete}
      isLoadingDeleted={isLoadingDeleted}
      user={notification.user}
      actionType={notification.actionType}
      readAt={notification.readAt}
      createdAt={notification.createdAt}>
      {({ time }) => (
        <>
          <TypographyText
            className="cursor-pointer text-sm text-gray-700"
            onClick={() => {
              router.push(
                `${configuration.path.candidates.detail(
                  propertiesRelated.profileId,
                  propertiesRelated.applicantId
                )}?profile-tabs=${FEEDBACK_TAB}`
              )
              onClick && onClick()
            }}>
            <Trans
              i18nKey="activity:notification:userSubmitInterviewFeedback"
              values={{
                userName: notification?.user?.fullName,
                score: t(
                  `candidates:feedback:${
                    FEEDBACK_OPTIONS.filter(
                      (s) => s.value === propertiesRelated?.score
                    )?.[0]?.value
                  }`
                ),
                interviewType: interviewEvents.filter(
                  (item: ISelectOption) =>
                    item.value === propertiesRelated?.interviewType
                )?.[0]?.supportingObj?.name,
                profileFullName: propertiesRelated?.profileFullName
              }}>
              <span className="font-medium text-gray-900" />
            </Trans>
            {time}
          </TypographyText>
        </>
      )}
    </NotificationItemLayout>
  )
}

export default UserSubmitInterviewFeedback
