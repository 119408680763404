import { useMutation } from 'urql'

function useMutationGraphQL({ query }: { query: string }) {
  const [result, trigger] = useMutation(query)
  const { data, fetching, error } = result

  return {
    trigger,
    isLoading: fetching,
    data,
    error
  }
}

export default useMutationGraphQL
