import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import QueryTenantShow from '../graphql/query-tenant-show'
import { IWorkspaceForm } from '../types'

const useWorkSpace = ({ shouldPause = false }: { shouldPause?: boolean }) => {
  const { data, trigger: fetchWorkspace } = useQueryGraphQL({
    query: QueryTenantShow,
    variables: {},
    shouldPause
  })

  return {
    tenantShow: data?.tenantShow as IWorkspaceForm,
    fetchWorkspace
  }
}

export default useWorkSpace
