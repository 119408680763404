const MutationUpdateSecuritySetting = `
  mutation (
    $enabling: Boolean,
    $provider: String,
    $appEnabling: Boolean,
    $tenantId: String,
    $clientId: String,
    $clientSecret: String,
    $redirectUri: String,
    $idleTime: Int,
  ) {
    tenantSecuritySettingUpdate(
      input: {
        enabling: $enabling,
        provider: $provider,
        appEnabling: $appEnabling,
        tenantId: $tenantId,
        clientId: $clientId,
        clientSecret: $clientSecret,
        redirectUri: $redirectUri,
        idleTime: $idleTime,
      }
    ) {
      ssoSetting {
        id
        values
      }
    }
  }
`

export default MutationUpdateSecuritySetting
