import { useContext } from 'react'
import { Client, Context } from 'urql'

export interface IResponseContextResult<T> {
  error: { graphQLErrors: Array<object> }
  data: {
    [key: string]: {
      collection: Array<T>
      metadata: {
        totalCount: number
        currentPage?: number
        extras?: {
          totalCustomFieldSetting?: number
        }
      }
    }
  }
}

function useContextGraphQL() {
  const useClient = () => useContext(Context)

  return {
    clientGraphQL: useClient() as any //Client
  }
}

export default useContextGraphQL
