import { gql } from 'urql'

const QueryUserPermissionsList = gql`
  query ($userId: Int, $roleId: Int) {
    userPermissionsList(userId: $userId, roleId: $roleId) {
      collection {
        visibility
        moduleName
        kind
        toggleable
        enabled
        objects
      }
    }
  }
`

export default QueryUserPermissionsList
