import { NextRouter } from 'next/router'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import HTMLDisplay from '~/components/HTMLDisplay'
import configuration from '~/configuration'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { isHTML } from '~/core/utilities/common'
import {
  INotification,
  INotificationJob,
  INotificationProfile
} from '~/lib/features/notifications/types'
import NotificationItemLayout from './NotificationItemLayout'

const CandidateApply: FC<{
  router: NextRouter
  notification: INotification
  onClick: () => void
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({ router, notification, onClick, onDelete, isLoadingDeleted }) => {
  const propertiesJobRelated = notification?.payload as INotificationJob
  const propertiesProfileRelated = notification?.payload as INotificationProfile

  return (
    <NotificationItemLayout
      onDelete={onDelete}
      isLoadingDeleted={isLoadingDeleted}
      recipient={{
        ...notification.recipient,
        fullName: propertiesProfileRelated?.profileFullName
      }}
      actionType={notification.actionType}
      readAt={notification.readAt}
      createdAt={notification.createdAt}>
      {({ time }) => (
        <>
          <TypographyText
            className="cursor-pointer text-sm text-gray-700"
            onClick={() => {
              router.push(
                configuration.path.candidates.detail(
                  propertiesProfileRelated.profileId
                )
              )
              onClick && onClick()
            }}>
            <Trans
              i18nKey="activity:notification:candidateApply"
              values={{
                userName: propertiesJobRelated?.profileFullName,
                jobTitle: propertiesJobRelated?.jobTitle
              }}>
              <span className="font-medium text-gray-900" />
            </Trans>
            {time}
          </TypographyText>

          {propertiesJobRelated?.content ? (
            <div
              className={cn(
                'mt-1.5 rounded border border-gray-100 bg-gray-50 px-1.5 pt-3',
                isHTML(propertiesJobRelated.content) ? 'pb-1.5' : 'pb-3'
              )}>
              <HTMLDisplay
                content={propertiesJobRelated.content}
                className="line-clamp-2 text-xs text-gray-900"
              />
            </div>
          ) : null}
        </>
      )}
    </NotificationItemLayout>
  )
}

export default CandidateApply
