import { parseCookies } from 'nookies'
import configuration from '~/configuration'
import { ICookies } from '~/core/@types/global'
import { getUserInfoByCookie } from '~/core/auth/get-user-info-by-cookie'
import {
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM,
  SESSION_COOKIE_TENANTS,
  SESSION_COOKIE_USER
} from '~/core/constants/cookies'

/**
 * @name redirectTo
 */
export function redirectTo({
  returnUrl,
  redirectPath
}: {
  returnUrl?: string
  redirectPath: string
}) {
  if (returnUrl) {
    const cleanReturnUrl = getPathFromReturnUrl(returnUrl)

    const queryParams = new URLSearchParams({
      returnUrl: cleanReturnUrl ?? configuration.path.default
    })

    // we build the sign in URL
    // appending the "returnUrl" query parameter so that we can redirect the user
    // straight to where they were headed and the "signOut" parameter
    // to force the client to sign the user out from the client SDK
    const destination = `${redirectPath}?${queryParams}`
    return {
      redirect: {
        permanent: false,
        destination
      }
    }
  } else {
    return {
      redirect: {
        permanent: false,
        destination: redirectPath
      }
    }
  }
}

export function getUserAuthentication(cookies: ICookies) {
  const authenticationToken = cookies[SESSION_COOKIE_USER]
  const tenantsToken = cookies[SESSION_COOKIE_TENANTS]
  const currentTenantToken = cookies[SESSION_COOKIE_CURRENT_TENANT]

  return getUserInfoByCookie({
    authCookie: authenticationToken,
    tenantsCookie: tenantsToken,
    currentTenantCookie: currentTenantToken
  })
}

export const handleAuthenticationFlow = ({
  user,
  resolvedUrl,
  query,
  redirectPath,
  extendedReturn,
  currentTenant,
  planInfo
}: {
  user: ReturnType<typeof getUserAuthentication>
  // incomming request path
  resolvedUrl: string
  query: { [key: string]: string | string[] | undefined }
  redirectPath: string
  extendedReturn: object
  currentTenant?: string
  planInfo?: { isExpired: boolean }
}) => {
  const returnUrlIncludeExtension = resolvedUrl
    ? String(resolvedUrl).startsWith(configuration.path.extension.list)
    : false

  try {
    const cookies = parseCookies()

    let companyBlocked =
      cookies[SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM] || false

    if (
      resolvedUrl.includes(configuration.path.requisitions.list) &&
      !!query.id &&
      !!query.userToken
    ) {
      return {
        props: {
          user,
          ...extendedReturn
        }
      }
    }

    if ([configuration.path.resumes.builderPreview].includes(resolvedUrl)) {
      return {
        props: {
          user,
          ...extendedReturn
        }
      }
    }

    if (!user) {
      return redirectTo({
        returnUrl: resolvedUrl,
        redirectPath: returnUrlIncludeExtension
          ? configuration.path.login
          : redirectPath
      })
    }

    // Case 1: Redirect user to onboarding page if tenants (list company of user) is empty
    if (
      user?.tenants?.data?.length === 0 &&
      !resolvedUrl.startsWith(configuration.path.onboarding)
    ) {
      return redirectTo({
        returnUrl: returnUrlIncludeExtension ? resolvedUrl : undefined,
        redirectPath: configuration.path.onboarding
      })
    }
    // Case 2: Redirect user to root page if tenants (list company of user) is only
    if (
      user?.tenants?.data?.length >= 1 &&
      resolvedUrl.startsWith(configuration.path.onboarding)
    ) {
      return redirectTo({
        redirectPath: configuration.path.default
      })
    }
    // Case 3: Redirect user to select tenants page if tenants (list company of user) is more than or equal two
    if (
      user?.tenants?.data?.length > 1 &&
      !currentTenant &&
      !resolvedUrl.startsWith(configuration.path.selectCompany)
    ) {
      return redirectTo({
        returnUrl: returnUrlIncludeExtension ? resolvedUrl : undefined,
        redirectPath: configuration.path.selectCompany
      })
    }

    // User try to access block page
    if (
      resolvedUrl.startsWith(configuration.path.tenantBlocked) &&
      !companyBlocked
    ) {
      return redirectTo({
        redirectPath: configuration.path.default
      })
    }
    // Check tenant plan
    if (!resolvedUrl.startsWith(configuration.path.selectCompany)) {
      if (
        planInfo &&
        planInfo.isExpired &&
        user.ownTenant &&
        !resolvedUrl.startsWith(configuration.path.settings.plans_expired)
      ) {
        return redirectTo({
          redirectPath: configuration.path.settings.plans_expired
        })
      }
      if (
        planInfo &&
        planInfo.isExpired &&
        !user.ownTenant &&
        !resolvedUrl.startsWith(configuration.path.errorExpiredPlan)
      ) {
        return redirectTo({
          redirectPath: configuration.path.errorExpiredPlan
        })
      }

      if (
        planInfo &&
        (resolvedUrl.startsWith(configuration.path.errorExpiredPlan) ||
          resolvedUrl.startsWith(configuration.path.settings.plans_expired)) &&
        !planInfo.isExpired
      ) {
        return redirectTo({
          redirectPath: configuration.path.default
        })
      }
    }

    return {
      props: {
        user,
        ...extendedReturn
      }
    }
  } catch (e) {
    // if the user is signed out, we save the requested URL
    // so, we can redirect them to where they originally navigated to

    return redirectTo({
      returnUrl: resolvedUrl,
      redirectPath: returnUrlIncludeExtension
        ? configuration.path.default
        : redirectPath
    })
  }
}

function getPathFromReturnUrl(returnUrl: string) {
  try {
    return new URL(returnUrl).pathname
  } catch (e) {
    return returnUrl.split('?')[0]
  }
}
