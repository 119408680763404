import { IFirebaseToken } from 'src/firebase/hooks/useFcmToken'
import { StateCreator } from 'zustand'
import { LayoutSlice } from './layout-slice'
import { ToastSlice } from './toast-slice'

export interface UserDevicesSlice {
  deviceRegistrations?: IFirebaseToken[]
  setDeviceRegistrations: (deviceRegistrations: IFirebaseToken[]) => void
}

export const createUserDevicesSlice: StateCreator<
  LayoutSlice & ToastSlice & UserDevicesSlice,
  [],
  [],
  UserDevicesSlice
> = (set: Function) => ({
  deviceRegistrations: undefined,
  setDeviceRegistrations: (deviceRegistrations: IFirebaseToken[]) =>
    set({ deviceRegistrations })
})
