import { NextRouter } from 'next/router'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import configuration from '~/configuration'
import { TypographyText } from '~/core/ui/Text'
import {
  INotification,
  INotificationProfile
} from '~/lib/features/notifications/types'
import NotificationItemLayout from './NotificationItemLayout'

const UserReferCandidate: FC<{
  router: NextRouter
  notification: INotification
  onClick: () => void
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({ router, notification, onClick, onDelete, isLoadingDeleted }) => {
  const propertiesRelated = notification?.payload as INotificationProfile

  return (
    <NotificationItemLayout
      onDelete={onDelete}
      isLoadingDeleted={isLoadingDeleted}
      user={notification.user}
      actionType={notification.actionType}
      readAt={notification.readAt}
      createdAt={notification.createdAt}>
      {({ time }) => (
        <>
          <TypographyText
            className="cursor-pointer text-sm text-gray-700"
            onClick={() => {
              router.push(
                configuration.path.candidates.detail(
                  propertiesRelated.profileId
                )
              )
              onClick && onClick()
            }}>
            <Trans
              i18nKey="activity:notification:userReferCandidate"
              values={{
                userName: notification?.user?.fullName,
                jobTitle: propertiesRelated?.jobTitle
              }}>
              <span className="font-medium text-gray-900" />
            </Trans>
            {time}
          </TypographyText>
        </>
      )}
    </NotificationItemLayout>
  )
}

export default UserReferCandidate
