import { formatDistanceToNowStrict } from 'date-fns'
import { FC, ReactElement, ReactNode } from 'react'
import { Trans } from 'react-i18next'
import { ILogoAndAvatarVariants } from '~/core/@types/global'
import { Avatar } from '~/core/ui/Avatar'
import { IconButton } from '~/core/ui/IconButton'
import { TypographyText } from '~/core/ui/Text'
import { Tooltip } from '~/core/ui/Tooltip'
import { cn } from '~/core/ui/utils'
import { defaultFormatDate } from '~/core/utilities/format-date'
import {
  NOTIFICATION_OVERDUE_DATE_TASK,
  NOTIFICATION_PENDING_REQUISITION_REMINDER
} from '~/lib/features/notifications/utilities/enum'

const NotificationItemLayout: FC<{
  children(data: { time: ReactNode }): ReactElement
  className?: string
  actionType: string
  readAt?: string
  user?: {
    defaultColour?: string
    avatarVariants?: ILogoAndAvatarVariants
    fullName?: string
  }
  recipient?: {
    defaultColour?: string
    avatarVariants?: ILogoAndAvatarVariants
    fullName?: string
  }
  createdAt?: string
  formatFromNow?: boolean
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({
  children,
  actionType,
  className,
  user,
  recipient,
  readAt,
  createdAt,
  formatFromNow = false,
  onDelete,
  isLoadingDeleted
}) => {
  return (
    <div className={cn('group relative flex w-full', className)}>
      {!readAt ? (
        <div className="mr-1 flex h-[32px] items-center pt-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6"
            height="6"
            viewBox="0 0 6 6"
            fill="none">
            <circle cx="3" cy="3" r="3" fill="#5D5AF9" />
          </svg>
        </div>
      ) : (
        <div className="mr-1 flex h-[32px] w-1.5 min-w-[6px] items-center pt-1" />
      )}
      <div className="flex w-full">
        {[
          NOTIFICATION_PENDING_REQUISITION_REMINDER,
          NOTIFICATION_OVERDUE_DATE_TASK
        ].includes(actionType) ? (
          <div className="w-[32px] min-w-[32px] pt-1">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="16" cy="16" r="16" fill="#FBF0D4" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0001 8.5C14.6077 8.5 13.2723 9.03028 12.2877 9.97419C11.3032 10.9181 10.7501 12.1983 10.7501 13.5332C10.7501 15.9439 10.2123 17.4409 9.71664 18.312C9.46779 18.7494 9.22548 19.0363 9.05716 19.2066C8.9728 19.292 8.90651 19.3486 8.8666 19.3805L8.82533 19.4119C8.55646 19.5889 8.43748 19.9128 8.53224 20.2128C8.62801 20.5161 8.91956 20.7235 9.25006 20.7235H22.7501C23.0806 20.7235 23.3721 20.5161 23.4679 20.2128C23.5626 19.9128 23.4437 19.5889 23.1748 19.4118L23.1335 19.3805C23.0936 19.3486 23.0273 19.292 22.943 19.2066C22.7746 19.0363 22.5323 18.7494 22.2835 18.312C21.7879 17.4409 21.2501 15.9439 21.2501 13.5332C21.2501 12.1983 20.6969 10.9181 19.7124 9.97419C18.7278 9.03028 17.3924 8.5 16.0001 8.5ZM17.9462 22.4253C18.154 22.0818 18.0321 21.6418 17.6738 21.4425H14.3261C13.9678 21.6418 13.8459 22.0818 14.0537 22.4253C14.2515 22.7522 14.5354 23.0235 14.877 23.2121C15.2185 23.4007 15.6058 23.5 16 23.5C16.3941 23.5 16.7814 23.4007 17.123 23.2121C17.4645 23.0235 17.7484 22.7522 17.9462 22.4253Z"
                fill="#F2BA2C"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7124 9.97419C18.7278 9.03028 17.3925 8.5 16.0001 8.5V20.7235H22.7501C23.0806 20.7235 23.3721 20.5161 23.4679 20.2128C23.5627 19.9128 23.4437 19.5889 23.1748 19.4118L23.1336 19.3805C23.0937 19.3486 23.0274 19.292 22.943 19.2066C22.7747 19.0363 22.5324 18.7494 22.2835 18.312C21.7879 17.4409 21.2501 15.9439 21.2501 13.5332C21.2501 12.1983 20.697 10.9181 19.7124 9.97419ZM17.123 23.2121C17.4646 23.0234 17.7485 22.7521 17.9462 22.4252H16V23.4999C16.3942 23.4999 16.7814 23.4007 17.123 23.2121Z"
                fill="#FBCA4F"
              />
            </svg>
          </div>
        ) : (
          <div className="pt-1">
            {user && (
              <Avatar
                size="md"
                color={user?.defaultColour}
                src={user?.avatarVariants?.thumb?.url}
                alt={user?.fullName}
              />
            )}
            {recipient && (
              <Avatar size="md" color="#FFFFFF" alt={recipient?.fullName} />
            )}
          </div>
        )}
        <div className="ml-2 flex w-full flex-col justify-center">
          {typeof children !== 'undefined'
            ? children({
                time: createdAt ? (
                  <Tooltip
                    content={defaultFormatDate(new Date(createdAt))}
                    classNameAsChild="inline-block pl-0.5">
                    <TypographyText className="whitespace-nowrap text-xs text-gray-500">
                      {formatDistanceToNowStrict(new Date(createdAt))}
                    </TypographyText>
                  </Tooltip>
                ) : (
                  ''
                )
              })
            : null}
        </div>
      </div>

      <div className="absolute right-0 top-0 rounded border border-solid border-gray-100 bg-white p-0.5 opacity-0 shadow-actions group-hover:opacity-100">
        <Tooltip
          content={<Trans i18nKey="tooltip:delete" />}
          classNameConfig={{
            content: 'z-[52]'
          }}>
          <IconButton
            isLoading={isLoadingDeleted}
            onClick={() => onDelete()}
            type="secondary-destructive"
            size="xs"
            iconMenus="Trash2"
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default NotificationItemLayout
