import { StateCreator } from 'zustand'
import { LayoutSlice } from './layout-slice'
import { ToastSlice } from './toast-slice'

export interface FilterSlice {
  filterValues?: any
  setFilterValues: (val: any) => void
  clearFilterValues: () => void
}

export const createFilterSlice: StateCreator<
  LayoutSlice & ToastSlice & FilterSlice,
  [],
  [],
  FilterSlice
> = (set: Function) => ({
  filterValues: null,
  setFilterValues: (val: any) => set({ filterValues: val }),
  clearFilterValues: () => set({ filterValues: null })
})
