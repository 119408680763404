import { NextRouter } from 'next/router'
import { FC } from 'react'
import { Trans } from 'react-i18next'
import HTMLDisplay from '~/components/HTMLDisplay'
import configuration from '~/configuration'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { isHTML } from '~/core/utilities/common'
import { JOB_NOTE_TAB } from '~/lib/features/jobs/utilities/enum'
import {
  INotification,
  INotificationJob
} from '~/lib/features/notifications/types'
import NotificationItemLayout from './NotificationItemLayout'

const UserMentionJobNote: FC<{
  router: NextRouter
  notification: INotification
  onClick: () => void
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({ router, notification, onClick, onDelete, isLoadingDeleted }) => {
  const propertiesRelated = notification?.payload as INotificationJob

  return (
    <NotificationItemLayout
      onDelete={onDelete}
      isLoadingDeleted={isLoadingDeleted}
      user={notification.user}
      actionType={notification.actionType}
      readAt={notification.readAt}
      createdAt={notification.createdAt}>
      {({ time }) => (
        <>
          <TypographyText
            className="cursor-pointer text-sm text-gray-700"
            onClick={() => {
              router.push(
                `${configuration.path.jobs.detail(
                  propertiesRelated.jobId
                )}?tabs=${JOB_NOTE_TAB}`
              )
              onClick && onClick()
            }}>
            <Trans
              i18nKey="activity:notification:userMentionJobNote"
              values={{
                userName: notification?.user?.fullName,
                jobTitle: propertiesRelated?.jobTitle
              }}>
              <span className="font-medium text-gray-900" />
            </Trans>
            {time}
          </TypographyText>

          {propertiesRelated?.content ? (
            <div
              className={cn(
                'mt-1.5 rounded border border-gray-100 bg-gray-50 px-1.5 pt-3',
                isHTML(propertiesRelated.content) ? 'pb-1.5' : 'pb-3'
              )}>
              <HTMLDisplay
                content={propertiesRelated.content}
                className="line-clamp-2 text-xs text-gray-900"
              />
            </div>
          ) : null}
        </>
      )}
    </NotificationItemLayout>
  )
}

export default UserMentionJobNote
