import {
  FetchNextPageOptions,
  FetchPreviousPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useInfiniteQuery
} from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { AnyVariables, DocumentInput } from 'urql'
import configuration from '~/configuration'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'

export type InfinityGraphPageReturnDataType<Data = {}> = {
  data: InfiniteData<Data> | undefined
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<Data, {}>>
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<InfiniteData<Data>, {}>>
  hasNextPage: boolean | undefined
  isFetching: boolean
  fetchPreviousPage: (
    options?: FetchPreviousPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<Data, {}>>
  isFetchedAfterMount: boolean
  isLoading: boolean
}

export const useInfinityGraphPage = <
  Data = any,
  Variables extends AnyVariables = AnyVariables
>({
  queryDocumentNote,
  getVariable,
  getPageAttribute,
  queryKey,
  enabled = true
}: {
  queryDocumentNote: DocumentInput<Data, Variables>
  getVariable: (page: number) => Partial<Variables>
  getPageAttribute: (
    last: Data,
    group: Data[]
  ) => { totalCount: number; pageLength: number }
  queryKey: string[]
  enabled?: boolean
}) => {
  const { clientGraphQL } = useContextGraphQL()
  const {
    data,
    fetchNextPage,
    fetchPreviousPage,
    refetch,
    hasNextPage,
    isFetching,
    isFetchedAfterMount,
    isLoading
  } = useInfiniteQuery<Data, {}, Data, string[]>(
    queryKey,
    useCallback(
      (context) => {
        return (
          clientGraphQL
            .query(queryDocumentNote, getVariable(context.pageParam || 1))
            .toPromise()
            //@ts-expect-error
            .then((rs) => {
              if (rs.error) {
                return catchErrorFromGraphQL({
                  error: rs.error,
                  page: configuration.path.candidates.list
                })
              }
              return rs.data || ({} as Data)
            })
        )
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [getVariable, queryDocumentNote]
    ),
    {
      getNextPageParam: (_lastGroup, groups) => {
        const { totalCount, pageLength } = getPageAttribute(_lastGroup, groups)

        const isHaveNext =
          (!totalCount && totalCount > 0) ||
          totalCount > groups.length * pageLength
        return isHaveNext ? groups.length + 1 : undefined
      },
      refetchOnWindowFocus: false,
      enabled: enabled
    }
  )
  return useMemo(
    () => ({
      data,
      fetchNextPage,
      fetchPreviousPage,
      refetch,
      hasNextPage,
      isFetching,
      isFetchedAfterMount,
      isLoading
    }),
    [
      data,
      fetchNextPage,
      refetch,
      hasNextPage,
      isFetching,
      fetchPreviousPage,
      isFetchedAfterMount,
      isLoading
    ]
  )
}
