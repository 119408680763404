'use client'

import * as ToastWrapper from '@radix-ui/react-toast'
import { ReactNode, useMemo } from 'react'
import ReactDOM from 'react-dom'
import { cn } from '~/core/ui/utils'
import { CloseButton } from '~/core/ui/CloseButton'
import IconWrapper from '~/core/ui/IconWrapper'
import { cva } from 'class-variance-authority'

const iconVariants = cva('', {
  variants: {
    type: {
      success: 'text-green-500',
      info: 'text-gray-600',
      warning: 'text-yellow-500',
      error: 'text-red-500'
    }
  },
  defaultVariants: {
    type: 'info'
  }
})

interface TagProps {
  open?: boolean
  setOpen?: (open: boolean) => void
  destroy?: () => void

  type: 'success' | 'info' | 'warning' | 'error'
  title: string
  description?: string
  actions?: Array<{
    alt?: string
    label?: string
    actionType?: '' | 'detail'
    callback?: () => void
  }>
  extraActions?: ReactNode
  duration?: number
  classNameConfig?: {
    viewport?: string
  }
  onClose?: () => void
}

const icons = {
  success: (iconClass: string) => (
    <IconWrapper name="CheckCircle" size={20} className={iconClass} />
  ),
  error: (iconClass: string) => (
    <IconWrapper name="XCircle" size={20} className={iconClass} />
  ),
  warning: (iconClass: string) => (
    <IconWrapper name="AlertTriangle" size={20} className={iconClass} />
  ),
  info: (iconClass: string) => (
    <IconWrapper name="AlertCircle" size={20} className={iconClass} />
  )
}

export const Toast = ({
  open = false,
  setOpen,
  type = 'info',
  title = '',
  description = '',
  actions = [],
  extraActions,
  duration = 5000,
  classNameConfig = {
    viewport: ''
  },
  onClose
}: TagProps) => {
  const Icon = useMemo(
    () => (type ? icons[type](cn(iconVariants({ type }))) : null),
    [type]
  )

  return (
    <ToastWrapper.Provider swipeDirection="right" duration={duration}>
      <ToastWrapper.Root
        className="grow-1 data-[state=open]:slideIn data-[state=closed]:hide data-[swipe=end]:swipeOut sm:last:mt-4 pointer-events-auto relative mt-4 w-full space-x-4 overflow-hidden rounded bg-white p-4 shadow-ats transition-all last:mt-0 data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none dark:bg-gray-900 dark:shadow-dark-ats"
        open={open}
        onOpenChange={setOpen}>
        <div className="flex items-start space-x-3">
          <div className="py-[2px]">{Icon}</div>

          <div className="w-full flex-1 space-y-2">
            <div className="space-y-1">
              {title ? (
                <ToastWrapper.Title>
                  <p className="text-base font-medium text-gray-900 dark:text-gray-200">
                    {title}
                  </p>
                </ToastWrapper.Title>
              ) : null}

              {description ? (
                <ToastWrapper.Description>
                  <p className="text-sm text-gray-700 dark:text-gray-300">
                    {description}
                  </p>
                </ToastWrapper.Description>
              ) : null}
            </div>

            {extraActions ? (
              <div className="flex items-center">{extraActions}</div>
            ) : (
              <>
                {actions?.length ? (
                  <div className="flex items-center">
                    {actions.map((session, index) => (
                      <ToastWrapper.Action
                        key={index}
                        asChild
                        className="inline-flex h-5 shrink-0 cursor-pointer items-center justify-center rounded-md disabled:pointer-events-none disabled:opacity-50"
                        altText={session.alt || `action-toast-${index}`}>
                        <div
                          onClick={() => {
                            if (session?.callback) {
                              session.callback()
                            }
                          }}>
                          {session.actionType === 'detail' &&
                          actions.length === 2 ? (
                            <div className="mx-2 h-[2px] w-[2px] rounded-sm bg-gray-400" />
                          ) : null}
                          <p
                            className={cn(
                              'text-sm font-medium',
                              session.actionType !== 'detail'
                                ? 'text-gray-700 dark:text-gray-300'
                                : 'text-primary-400 dark:text-primary-400'
                            )}>
                            {session.label}
                          </p>
                          {session.actionType === 'detail' ? (
                            <div className="ml-1.5">
                              <IconWrapper
                                name="ArrowRight"
                                className="text-primary-400 dark:text-primary-400"
                                size={16}
                              />
                            </div>
                          ) : null}
                        </div>
                      </ToastWrapper.Action>
                    ))}
                  </div>
                ) : null}
              </>
            )}
          </div>

          <ToastWrapper.Close>
            <CloseButton asChild onClick={() => {
              setOpen && setOpen(false)
              onClose && onClose()
            }} />
          </ToastWrapper.Close>
        </div>
      </ToastWrapper.Root>

      <ToastWrapper.Viewport
        className={cn(
          'fixed bottom-0 right-0 z-[2147483647] m-0 flex max-h-screen w-fit min-w-[300px] max-w-[340px] list-none flex-col-reverse gap-[10px] p-4 outline-none tablet:max-w-[526px] tablet:pb-6 tablet:pl-0 tablet:pr-6 tablet:pt-0',
          classNameConfig.viewport
        )}
      />
    </ToastWrapper.Provider>
  )
}

export function openToast({
  type,
  title,
  description,
  actions,
  extraActions,
  classNameConfig
}: TagProps) {
  const div = document.createElement('div')
  document.body.appendChild(div)
  ReactDOM.render(
    <Toast
      type={type}
      title={title}
      description={description}
      actions={actions}
      extraActions={extraActions}
      classNameConfig={classNameConfig}
      destroy={() => setTimeout(() => ReactDOM.unmountComponentAtNode(div), 0)}
    />,
    div
  )
}
