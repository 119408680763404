import { signOut as signOutNexAuth, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import configuration from '~/configuration'
import useMiddlewareRequest from '~/core/middleware/use-middleware-request'

export function useSubmitSignOutCHUB() {
  const router = useRouter()
  const { data: session } = useSession()
  const { trigger, isMutating, data, error } = useMiddlewareRequest({
    endpoint: configuration.path.careerHub.signOut(router.query.slug as string),
    method: 'POST'
  })

  const handleSignOut = async () => {
    trigger({}).then(async (result) => {
      if (result.success) {
        if (session) {
          await signOutNexAuth({ redirect: false })
        }
        router.push(
          configuration.path.careerHub.login(router.query.slug as string)
        )
      }
    })
  }

  return {
    handleSignOut,
    isLoading: isMutating,
    data,
    error
  }
}
