import { ROLE_ADMIN, ROLE_LIMITED_MEMBER, ROLE_MEMBER } from './env'

export const ROLE = {
  LimitedMember: 'Limited member',
  Admin: 'Admin',
  Everyone: 'Everyone',
  Member: 'Member'
}

export const ROLE_CODE = {
  LimitedMember: ROLE_LIMITED_MEMBER || '',
  Admin: ROLE_ADMIN || '',
  Member: ROLE_MEMBER || ''
}
