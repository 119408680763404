import { StateCreator } from 'zustand'
import { LayoutSlice } from './layout-slice'
import { UserSlice } from './user-slice'
import { IToast } from '../../core/@types/global'

export interface ToastSlice {
  configToast: IToast
  setToast: (state: IToast) => void
  setToastClose: () => void
  clearToast: () => void
}

export const createToastSlice: StateCreator<
  UserSlice & LayoutSlice & ToastSlice,
  [],
  [],
  ToastSlice
> = (set: Function) => ({
  configToast: {
    open: false,
    type: 'info',
    title: '',
    description: '',
    actions: [],
    extraActions: undefined,
    classNameConfig: undefined
  },
  setToast: (configToast: IToast) => set({ configToast }),
  setToastClose: () =>
    set((state: { configToast: IToast }) => ({
      configToast: {
        ...state.configToast,
        open: false
      }
    })),
  clearToast: () =>
    set(() => ({
      configToast: {
        open: false,
        type: '',
        title: '',
        description: '',
        actions: [],
        extraActions: undefined,
        classNameConfig: undefined
      }
    }))
})
