import { IRouterWithID } from '../@types/global'

export const ERROR_500_URL = '/500'
export const ERROR_404_URL = '/404'
export const ERROR_EXPIRED_PLAN_URL = '/expired-plan'
export const ERROR_ACCESS_DENIED_URL = '/access-denied'
export const TENANT_BLOCKED = '/block-company'
export const EXPIRED_PLAN = '/expired-plan'
export const DEFAULT_URL = '/dashboard'
export const REGISTER_URL = '/register'
export const LOGIN_URL = '/login'
export const LOGIN_SSO_URL = '/login/sso'
export const VERIFY_EMAIL_URL = '/verify-email'
export const VERIFY_EMAIL_SSO_URL = '/verify-email/sso'
export const AUTH_ERROR_URL = '/auth/error'
export const ONBOARDING_URL = '/onboarding'
export const SELECT_COMPANY_URL = '/select-company'
export const CONTACT_US_URL = '/contact-us'
export const EXTENSION_URL = '/ext'
export const COMPANY_NAME = '/company-name'
export const SETTINGS_MEMBERS_URL = '/settings/members'
export const SETTINGS_DEPARTMENTS_URL = '/settings/departments'
export const SETTINGS_LOCATIONS_URL = '/settings/locations'
export const SETTINGS_WORKSPACE_URL = '/settings/workspace'
export const SETTINGS_ACCOUNT_URL = '/settings/account'
export const SETTINGS_PROFILES_URL = '/settings/profiles'
export const SETTINGS_ACCOUNT_PREFERENCES_URL = '/settings/preferences'
export const SETTINGS_CAREERS_URL = '/settings/careers-page'
export const SETTINGS_CAREERS_EDITOR_URL = '/settings/careers-page/editor'
export const SETTINGS_EMAIL_TEMPLATES = '/settings/email-templates'
export const SETTING_DISQUALIFY_REASONS = '/settings/disqualify-reasons'
export const SETTING_CUSTOM_FIELDS = '/settings/custom-fields'
export const SETTING_HIRING_PIPELINES = '/settings/hiring-pipeline'
export const SETTING_INTERVIEW_KITS = '/settings/interview-kits'
export const SETTING_PROFILE_TEMPLATES = '/settings/profile-templates'
export const SETTING_SECURITY = '/settings/security'
export const SETTING_TAGS = '/settings/tags'
export const SETTING_SKILLS = '/settings/skills'
export const SETTING_REFERRALS = '/settings/referrals'
export const SETTING_REQUISITIONS = '/settings/requisitions'
export const SETTING_PLANS = '/settings/plans'
export const SETTING_PLANS_EXPIRED = '/settings/plans/expired'
export const SETTING_IMPORT = '/settings/import'
export const SETTING_AUDIT_LOGS = '/settings/audit-logs'
export const SETTINGS_TEAMS_URL = '/settings/teams'

export const JOBS_LIST_URL = '/jobs'
export const JOBS_DETAIL_URL = (jobId: number) => `/jobs/${jobId}`
export const JOBS_EDIT_URL = (jobId: number) => `/jobs/${jobId}/edit`
export const JOBS_TABS_DETAIL_URL = (jobId: number) =>
  `/jobs/${jobId}?id=${jobId}&tabs=details`
export const JOBS_CREATE_DUPLICATE_URL = (jobId: number) =>
  `/jobs/create?duplicate_job=${jobId}&id=${jobId}`
export const JOBS_CREATE_URL = '/jobs/create'
export const JOB_APPLY_URL = '/apply'
export const REQUISITION_LIST = '/requisitions'
export const REQUISITION_CREATE_URL = 'requisitions/create'
export const REQUISITION_EDIT_URL = (requisitionId: number) =>
  `/requisitions/${requisitionId}/edit`
export const REQUISITION_CREATE_DUPLICATE_URL = (requisitionId: number) =>
  `/requisitions/create?duplicate_requisition=${requisitionId}&id=${requisitionId}`
export const TASKS_LIST_URL = '/tasks'

export const CALENDAR_LIST_URL = '/calendar'

export const REFERRAL_LIST_URL = '/referrals'

export const PLACEMENTS_LIST_URL = '/placements'

export const REPORTS_OVERVIEW_URL = '/reports/overview'
export const REPORTS_TIME_TO_HIRE_URL = '/reports/time-to-hire'
export const REPORTS_PRODUCTIVITY_URL = '/reports/productivity'
export const REPORTS_HIRING_FUNNEL_URL = '/reports/hiring-funnel'
export const APPLICANT_FEEDBACK_URL = (applicantId: number) =>
  `/applicant-feedback/${applicantId}`
export const APPLICANT_FEEDBACK_DETAIL_URL = (
  applicantId: number,
  feedbackId: number
) => `/applicant-feedback/${applicantId}/feedback/${feedbackId}`

export const CANDIDATES_LIST_URL = '/candidates'
export const CANDIDATES_DETAIL_URL = (
  profileId?: IRouterWithID,
  applicantId?: IRouterWithID
) => {
  if (!applicantId && profileId) return `/candidates/${profileId}`

  if (!profileId) return CANDIDATES_LIST_URL
  return `/candidates/${profileId}/applications/${applicantId}`
}
export const CANDIDATES_EDIT_URL = (profileId?: IRouterWithID) => {
  if (!profileId) return CANDIDATES_LIST_URL
  return `/candidates/${profileId}/edit`
}

export const CAREERS_LIST_URL = '/careers'
export const INTERVIEW_LIST_URL = '/interviews'
export const INTERVIEW_DETAIL_URL = (interviewId: number) =>
  `/interviews/${interviewId}`

export const TALENT_POOL_LIST_URL = '/talent-pools'
export const TALENT_POOL_DETAIL_URL = (talentPoolId: number) =>
  `/talent-pools/${talentPoolId}`
export const TERMS_OF_USE_URL = '/term-of-services'
export const PRIVACY_POLICY_URL = '/privacy-policy'

// Agency url
export const AGENCY_COMPANIES_URL = '/companies'
export const AGENCY_SETTINGS_COMPANY_STATUS_URL = '/settings/company-status'
export const AGENCY_PLACEMENTS_URL = '/agency/placements'

export const RESUMES_EDITOR_URL = '/settings/profiles/edit'
export const RESUMES_BUILDER_PREVIEW_URL =
  '/settings/profiles/edit/builder-preview'
export const SETTINGS_LIST_URL = '/settings'

export const SHARE_CV_URL = (id?: string) => `/share/cv/${id}`
export const AGENCY_CONTACTS_URL = '/contacts'
// Dashboard
export const DASHBOARD_URL = '/dashboard'
export const CAREERHUB_URL = '/careerhub'
export const CAREER_HUB_JOBS = '/careerhub/[slug]/jobs'
export const CAREER_HUB_REFERRALS = '/careerhub/[slug]/referrals'
export const CAREER_HUB_MYJOBS = '/careerhub/[slug]/my-jobs'
export const CAREER_HUB_PROFILE = '/careerhub/[slug]/profile'
export const CAREER_HUB_ACCOUNT = '/account'
