export const NOTIFICATION_STATUS = {
  done: 'done',
  undone: 'undone'
}

export const NOTIFICATION_USER_UPDATE_JOB_STATUS = 'user_update_job_status'
export const NOTIFICATION_USER_MENTION_JOB_NOTE = 'user_mention_job_note'
export const NOTIFICATION_USER_MENTION_PROFILE_NOTE =
  'user_mention_profile_note'
export const NOTIFICATION_USER_MENTION_APPLICANT_NOTE =
  'user_mention_applicant_note'
export const NOTIFICATION_USER_MENTION_COMPANY_NOTE =
  'user_mention_company_note'
export const NOTIFICATION_USER_ASSIGNED_TASK = 'user_assigned_task'
export const NOTIFICATION_USER_COMPLETE_TASK = 'user_complete_task'
export const NOTIFICATION_OVERDUE_DATE_TASK = 'overdue_date_task'
export const NOTIFICATION_CANDIDATE_APPLY = 'candidate_apply'
export const NOTIFICATION_USER_SETUP_INTERVIEW = 'user_setup_interview'
export const NOTIFICATION_USER_RESCHEDULE_INTERVIEW =
  'user_reschedule_interview'
export const NOTIFICATION_USER_CANCEL_INTERVIEW = 'user_cancel_interview'
export const NOTIFICATION_CANDIDATE_PICK_INTERVIEW_TIME_SLOT =
  'candidate_pick_interview_time_slot'
export const NOTIFICATION_CANDIDATE_REJECT_INTERVIEW_TIME_SLOT =
  'candidate_reject_interview_time_slot'
export const NOTIFICATION_USER_REFER_CANDIDATE = 'user_refer_candidate'
export const NOTIFICATION_USER_INTERVIEW_FEEDBACK =
  'user_submit_interview_feedback'
export const NOTIFICATION_USER_SUBMIT_REQUISITION = 'user_submit_requisition'
export const NOTIFICATION_FULLY_APPROVED_REQUISITION =
  'fully_approved_requisition'
export const NOTIFICATION_USER_REJECT_REQUISITION = 'user_reject_requisition'
export const NOTIFICATION_PENDING_REQUISITION_REMINDER =
  'pending_requisition_reminder'
export const NOTIFICATION_USER_DELETE_COMPANY = 'user_delete_company'
export const NOTIFICATION_USER_CHANGE_COMPANY_OWNERSHIP =
  'user_change_company_ownership'
