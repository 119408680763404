import { StateCreator } from 'zustand'
import { LayoutSlice } from './layout-slice'
import { ToastSlice } from './toast-slice'

export interface ITenantFeatureSettingType {
  key: string
  enabling_feature: boolean
}

export interface TenantFeatureSettingSlice {
  featureSetting?: ITenantFeatureSettingType[]
  setFeatureSetting: (featureSetting: ITenantFeatureSettingType[]) => void
}

export const createTenantFeatureSettingSlice: StateCreator<
  LayoutSlice & ToastSlice & TenantFeatureSettingSlice,
  [],
  [],
  TenantFeatureSettingSlice
> = (set: Function) => ({
  featureSetting: undefined,
  setFeatureSetting: (featureSetting: ITenantFeatureSettingType[]) =>
    set({ featureSetting })
})
