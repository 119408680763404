import { TFunction } from 'next-i18next'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import MutationUpdateSecuritySetting from '../graphql/mutation-update-security-setting'
import QueryTenantSecuritySettingShow from '../graphql/query-get-security-setting'
import { ISecuritySetting } from '../types'

const useSecuritySetting = ({
  shouldPause = true,
  t
}: {
  shouldPause?: boolean
  t?: TFunction
}) => {
  const { data, trigger: fetchSecuritySetting } = useQueryGraphQL({
    query: QueryTenantSecuritySettingShow,
    variables: {},
    shouldPause
  })

  const {
    trigger: triggerUpdateSecuritySetting,
    isLoading: isLoadingUpdateSecuritySetting
  } = useSubmitCommon(MutationUpdateSecuritySetting)

  // 5 minutes, 15 minutes, 30 minutes, 45 minutes, 1 hour, 2 hours, 3 hours, 4 hours.
  const optionsIdleTimers = t
    ? [
        {
          value: '0',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:0')}`
          }
        },
        {
          value: '5',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:5')}`
          }
        },
        {
          value: '15',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:15')}`
          }
        },
        {
          value: '30',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:30')}`
          }
        },
        {
          value: '45',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:45')}`
          }
        },
        {
          value: '60',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:60')}`
          }
        },
        {
          value: '120',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:120')}`
          }
        },
        {
          value: '180',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:180')}`
          }
        },
        {
          value: '240',
          supportingObj: {
            name: `${t('settings:security:idleTimerOptions:240')}`
          }
        }
      ]
    : []

  return {
    securitySettingShow: data?.tenantSecuritySettingShow
      ?.values as ISecuritySetting,
    fetchSecuritySetting,
    triggerUpdateSecuritySetting,
    isLoadingUpdateSecuritySetting,
    optionsIdleTimers
  }
}

export default useSecuritySetting
