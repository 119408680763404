import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '~/core/ui/Skeleton'
import { TypographyText } from '~/core/ui/Text'
import { cn, debounce } from '~/core/ui/utils'
import { InfiniteScrollProps } from '~/lib/features/jobs/types'

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  wrapperRef,
  loadData,
  haveNext,
  children,
  skeletonElement,
  isShowEndLabel = true,
  className,
  classNameEndOfList
}) => {
  const { t } = useTranslation()
  const [isFetching, setIsFetching] = useState(false)
  const [isScrollChange, setIsScrollChange] = useState(false)

  const loadDataIfScrollAppeared = useCallback(() => {
    const offsetHeight =
      wrapperRef?.current?.offsetHeight || document.body.offsetHeight
    const scrollHeight = wrapperRef?.current
      ? wrapperRef?.current?.scrollHeight
      : document.body.scrollHeight

    if (haveNext && Math.round(scrollHeight) <= Math.round(offsetHeight)) {
      setIsFetching(true)
    }
  }, [haveNext, wrapperRef])

  const checkScrollTriggerLoad = useCallback(() => {
    const scrollHeight =
      wrapperRef?.current?.scrollHeight || document.body.scrollHeight
    const currentPosition = wrapperRef?.current
      ? wrapperRef?.current?.scrollTop + wrapperRef?.current?.offsetHeight
      : window.innerHeight + window.scrollY

    if (haveNext && Math.round(currentPosition) >= Math.round(scrollHeight)) {
      setIsFetching(true)
    }
  }, [haveNext, wrapperRef])

  const setScrollChange = useCallback(
    debounce(() => setIsScrollChange(true), 500),
    []
  )
  const handleScroll = useCallback(() => {
    setScrollChange()
    if (!isFetching) {
      checkScrollTriggerLoad()
    }
  }, [isFetching, checkScrollTriggerLoad])
  useEffect(() => {
    ;(wrapperRef?.current || window).addEventListener('scroll', handleScroll)
    loadDataIfScrollAppeared() // Check if scroll already appeared
    return () =>
      (wrapperRef?.current || window).removeEventListener(
        'scroll',
        handleScroll
      )
  }, [handleScroll, loadDataIfScrollAppeared, wrapperRef])

  useEffect(() => {
    if (isFetching) {
      if (haveNext) {
        loadData().then(() => {
          setIsFetching(false)
        })
      } else {
        setIsFetching(false)
      }
    }
  }, [isFetching, haveNext])

  useEffect(() => {
    if (haveNext) {
      loadDataIfScrollAppeared()
    }
  }, [haveNext, loadDataIfScrollAppeared])

  return (
    <div className={className}>
      {children}{' '}
      {isFetching &&
        (skeletonElement ? (
          skeletonElement
        ) : (
          <div className="mt-4 text-center text-base text-gray-600">
            {[1, 2, 3, 4, 5].map((index) => (
              <div key={index} className="mb-4 flex items-center space-x-3">
                <Skeleton className="h-6 w-6 min-w-[24px] rounded-full" />
                <div className="w-full space-y-1">
                  <Skeleton className="h-2 w-2/3" />
                  <Skeleton className="h-2 w-full" />
                </div>
              </div>
            ))}
          </div>
        ))}
      {isShowEndLabel && isScrollChange && haveNext === false && (
        <div>
          <TypographyText
            className={cn(
              'mt-4 text-center text-base text-gray-600',
              classNameEndOfList
            )}>
            {t('careers:thatAllJobs')}
          </TypographyText>
        </div>
      )}
    </div>
  )
}
export default InfiniteScroll
