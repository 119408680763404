import { useRouter } from 'next/router'
import { PropsWithChildren } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import {
  AUTH_ERROR_URL,
  LOGIN_URL,
  REGISTER_URL,
  VERIFY_EMAIL_URL
} from '~/core/constants/url'
import MobileNotSupportView from './MobileNotSupportView'

const ALLOWED_MOBILE_PATH_REGEXS = [
  `^${LOGIN_URL}*`,
  `^${REGISTER_URL}$`,
  `^${AUTH_ERROR_URL}$`,
  `^${VERIFY_EMAIL_URL}$`,
  '^/careers/(.*)$',
  '/requisitions/((?!(create|edit)).)*$'
]

const NoSupportMobileDetectView = ({ children }: PropsWithChildren) => {
  const router = useRouter()

  if (isMobile && !isTablet) {
    return <MobileNotSupportView />
  }

  return (
    <>
      {!isMobile ||
      isTablet ||
      !!ALLOWED_MOBILE_PATH_REGEXS.find((path) =>
        router.asPath.match(new RegExp(path))
      ) ? (
        children
      ) : (
        <MobileNotSupportView />
      )}
    </>
  )
}

export default NoSupportMobileDetectView
