import { ITenantDetail } from '~/core/@types/global'

export const mappingCurrentTenantCookie = (currentTenant?: ITenantDetail) => {
  if (!currentTenant) return {}

  return {
    id: currentTenant.id,
    name: currentTenant.name,
    logoVariants: {
      medium: { url: currentTenant.logoVariants?.medium?.url },
      thumb: { url: currentTenant.logoVariants?.thumb?.url }
    },
    phoneNumber: currentTenant.phoneNumber,
    companySize: currentTenant.companySize,
    companyKind: currentTenant.companyKind,
    interacted: currentTenant.interacted,
    slug: currentTenant.slug,
    tenantPlan: {
      defaultTrial: currentTenant.tenantPlan?.defaultTrial,
      name: currentTenant.tenantPlan?.name
    },
    currency: currentTenant.currency,
    canonicalUrl:
      currentTenant.careerSiteSettings?.canonical_url ||
      currentTenant.canonicalUrl,
    careerSiteSettings: {
      page_title: currentTenant.careerSiteSettings?.page_title,
      languages: {
        en: {
          enable: currentTenant.careerSiteSettings?.languages?.en?.enable,
          default: currentTenant.careerSiteSettings?.languages?.en?.default
        },
        ja: {
          enable: currentTenant.careerSiteSettings?.languages?.ja?.enable,
          default: currentTenant.careerSiteSettings?.languages?.ja?.default
        }
      },
      canonical_url:
        currentTenant.careerSiteSettings?.canonical_url ||
        currentTenant.canonicalUrl,
      department_visibility:
        currentTenant.careerSiteSettings?.department_visibility,
      enabling: currentTenant.careerSiteSettings?.enabling,
      enablingCareerSiteSetting:
        currentTenant.careerSiteSettings?.enablingCareerSiteSetting ||
        currentTenant.enablingCareerSiteSetting
    }
  }
}
