'use client'

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

const TypographyText: FCC<{
  className?: string
  tagName?: 'p' | 'div'
  onClick?: () => void
}> = ({ className, children, tagName = 'p', onClick }) => {
  const Tag = tagName
  return (
    <Tag className={className} onClick={onClick}>
      {children}
    </Tag>
  )
}

export { TypographyText }
