import { NextRouter } from 'next/router'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import { showDateTime } from '~/core/utilities/format-date'
import {
  INotification,
  INotificationInterview
} from '~/lib/features/notifications/types'
import NotificationItemLayout from './NotificationItemLayout'

const CandidatePickInterviewTimeSlot: FC<{
  router: NextRouter
  currentTenantName?: string
  notification: INotification
  onClick: () => void
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({
  router,
  currentTenantName,
  notification,
  onClick,
  onDelete,
  isLoadingDeleted
}) => {
  const { i18n } = useTranslation()
  const interviewEvents = useEnumsData({
    enumType: 'InterviewEvent',
    locale: i18n.language
  })
  const propertiesRelated = notification?.payload as INotificationInterview

  return (
    <NotificationItemLayout
      onDelete={onDelete}
      isLoadingDeleted={isLoadingDeleted}
      recipient={{
        ...notification.recipient,
        fullName: propertiesRelated?.profileFullName
      }}
      actionType={notification.actionType}
      readAt={notification.readAt}
      createdAt={notification.createdAt}>
      {({ time }) => (
        <>
          <TypographyText
            className="cursor-pointer text-sm text-gray-700"
            onClick={() => {
              window.open(
                `${PUBLIC_APP_URL}/careers/${currentTenantName}/events/${propertiesRelated.eventUuid}`,
                '_blank'
              )
              onClick && onClick()
            }}>
            <Trans
              i18nKey="activity:notification:candidatePickInterviewTimeSlot"
              values={{
                userName: propertiesRelated?.profileFullName,
                interviewType: interviewEvents.filter(
                  (item: ISelectOption) =>
                    item.value === propertiesRelated?.interviewType
                )?.[0]?.supportingObj?.name,
                timeFormatted: showDateTime({
                  start: propertiesRelated?.fromDatetime,
                  end: propertiesRelated?.toDatetime,
                  timezone: propertiesRelated?.timezone
                })
              }}>
              <span className="font-medium text-gray-900" />
            </Trans>
            {time}
          </TypographyText>
        </>
      )}
    </NotificationItemLayout>
  )
}

export default CandidatePickInterviewTimeSlot
