'use client'

import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'
import { cva } from 'class-variance-authority'
import React, { forwardRef } from 'react'
import { Slot } from '@radix-ui/react-slot'

const closeButtonVariants = cva('flex items-center outline-none', {
  variants: {
    disabled: {
      default:
        'text-gray-600 hover:text-primary-600 focus:text-primary-600 dark:text-gray-400 dark:hover:text-primary-300 dark:focus:text-primary-300',
      disabled: 'pointer-events-none text-gray-400 dark:text-gray-700'
    }
  },
  defaultVariants: {
    disabled: 'default'
  }
})

interface CloseButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isDisabled?: boolean
  iconClassName?: string
  iconName?: LucideIconName
  asChild?: boolean
}

const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  (
    { isDisabled = false, onClick, iconClassName = '', iconName = 'X', asChild },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        ref={ref}
        type="button"
        className={cn(
          closeButtonVariants({ disabled: isDisabled ? 'disabled' : 'default' })
        )}
        onClick={(event) => {
          if (onClick) {
            onClick(event)
          }
        }}>
          <div>
            <IconWrapper className={iconClassName} name={iconName} />
          </div>
      </Comp>
    )
  }
)

CloseButton.displayName = 'CloseButton'

export { CloseButton }
export type { CloseButtonProps }
