import { IColorBadgeType } from '~/core/ui/Badge'
import { LucideIconName } from '~/core/ui/IconWrapper'
import { ISelectOption } from '~/core/ui/Select'
import { DateTimeMethodType } from '../types'

export const INTERVIEW_STATUS_COLOR: {
  [key: string]: {
    borderColor: string
    bgColor: string
    bgActiveColor: string
    hoverBgActiveColor: string
    textColor: string
    textActiveColor: string
    hoverTextActiveColor: string
    badgeColor: IColorBadgeType
  }
} = {
  blue: {
    borderColor: 'border-l-primary-400',
    bgColor: 'bg-blue-100',
    bgActiveColor: 'bg-primary-400',
    hoverBgActiveColor: 'hover:bg-primary-400',
    textColor: 'text-blue-800',
    textActiveColor: 'text-white',
    hoverTextActiveColor: 'group-hover:text-white',
    badgeColor: 'blue'
  },
  red: {
    borderColor: 'border-l-red-500',
    bgColor: 'bg-ava-bg-50',
    bgActiveColor: 'bg-red-500',
    hoverBgActiveColor: 'hover:bg-red-500',
    textColor: 'text-red-800',
    textActiveColor: 'text-white',
    hoverTextActiveColor: 'group-hover:text-white',
    badgeColor: 'red'
  }
}

export const FEEDBACK_OPTIONS: Array<{
  value: string
  iconMenus?: LucideIconName
  selectedConfig?: {
    borderColorClassName?: string
    backgroundColorClassName?: string
    textColorClassName?: string
  }
}> = [
  {
    value: 'strong_no',
    iconMenus: 'Ban',
    selectedConfig: {
      borderColorClassName: '!border-red-500',
      backgroundColorClassName: '!bg-red-500',
      textColorClassName: '!text-white'
    }
  },
  {
    value: 'no',
    iconMenus: 'ThumbsDown',
    selectedConfig: {
      borderColorClassName: '!border-orange-500',
      backgroundColorClassName: '!bg-orange-500',
      textColorClassName: '!text-white'
    }
  },
  {
    value: 'yes',
    iconMenus: 'ThumbsUp',
    selectedConfig: {
      borderColorClassName: '!border-chart-800',
      backgroundColorClassName: '!bg-chart-800',
      textColorClassName: '!text-white'
    }
  },
  {
    value: 'strong_yes',
    iconMenus: 'Star',
    selectedConfig: {
      borderColorClassName: '!border-green-500',
      backgroundColorClassName: '!bg-green-500',
      textColorClassName: '!text-white'
    }
  }
]
export const OVERALL_FEEDBACK__OPTIONS: Array<{
  value: string
  iconMenus?: LucideIconName | 'QuestionMarkCircle'
}> = [
  {
    value: 'strong_no',
    iconMenus: 'Ban'
  },
  {
    value: 'no',
    iconMenus: 'ThumbsDown'
  },
  {
    value: 'yes',
    iconMenus: 'ThumbsUp'
  },
  {
    value: 'strong_yes',
    iconMenus: 'Star'
  },
  {
    value: 'pending_feedback',
    iconMenus: 'QuestionMarkCircle'
  }
]

export const DATE_TIME_TYPE_VALUE: { [key: string]: DateTimeMethodType } = {
  specific: 'set_specific_time',
  self_schedule: 'candidate_self_schedule'
}

export const DURATION_SELECTED_SLOT: Array<ISelectOption> = [
  { value: '30_mins' },
  { value: '1_hour' },
  { value: '1_hour_30_mins' },
  { value: '2_hour' },
  { value: '2_hour_30_mins' },
  { value: '3_hour' }
]

export const INTERVIEW_STATE_VALUE = {
  pending: 'pending',
  confirmed: 'confirmed',
  schedule_failed: 'schedule_failed'
}

export const GROUPING_ATTENDEE_ID = {
  hiring: 'hiring',
  other: 'other'
}

export const AGENCY_GROUPING_ATTENDEE_ID = {
  client: 'client',
  hiring: 'hiring',
  other: 'other'
}
