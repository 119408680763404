const MutationDeleteNotification = `
  mutation (
    $id: String,
    $all: Boolean
  ) {
    notificationsDelete(
      input: {
        id: $id,
        all: $all
      }
    ) {
      success
    }
  }
`

export default MutationDeleteNotification
