import { GetServerSidePropsContext } from 'next'
import { getProviders } from 'next-auth/react'
import { parseCookies } from 'nookies'
import configuration from '~/configuration'
import { ICookies } from '~/core/@types/global'
import { getUserInfoByCookie } from '~/core/auth/get-user-info-by-cookie'
import {
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_TENANTS,
  SESSION_COOKIE_USER
} from '~/core/constants/cookies'
import { withTranslationProps } from '~/lib/next/with-translation'

const DEFAULT_OPTIONS = {
  redirectPath: configuration.path.default,
  locale: configuration.site.locale ?? 'en',
  localeNamespaces: []
}

/**
 * @description A server props pipe to deny access to auth pages while logged in
 * For example, this is to be used in pages where logged-in users are not
 * supposes to see, like the sign in page
 * @param ctx
 * @param options
 */
export async function withAuthProps(
  ctx: GetServerSidePropsContext,
  options: Partial<typeof DEFAULT_OPTIONS> = DEFAULT_OPTIONS
) {
  const mergedOptions = getAppPropsOptions(ctx.locale, options)
  const redirectPath =
    (ctx.query.returnUrl as string) || (mergedOptions.redirectPath as string)
  const { props: translationProps } = await withTranslationProps(mergedOptions)
  const providers = await getProviders()

  try {
    const cookies = parseCookies(ctx)
    const user = await getUserAuthentication(cookies)

    if (!user) {
      return {
        props: {
          providers: providers ?? [],
          ...translationProps
        }
      }
    }
    return handleRedirectForLoginRegisterPage({
      redirectPath,
      user,
      cookies,
      resolvedUrl: ctx.resolvedUrl
    })
  } catch (e) {
    return {
      props: {
        providers: providers ?? [],
        ...translationProps
      }
    }
  }
}

export const getSlugTenantFromCareerHubUrl = (path: string) => {
  const parts = path.split('/')
  const careerhubIndex = parts.indexOf('careerhub')
  return careerhubIndex !== -1 && careerhubIndex + 1 < parts.length
    ? parts[careerhubIndex + 1]
    : undefined
}

export function handleRedirectForLoginRegisterPage({
  redirectPath,
  user,
  resolvedUrl,
  cookies
}: {
  redirectPath: string
  user: any
  resolvedUrl: string
  cookies: { [key: string]: string }
}) {
  const returnUrlIncludeExtension = redirectPath
    ? String(redirectPath).startsWith(configuration.path.extension.list)
    : false

  const isCareerHubPath = resolvedUrl.search('/careerhub/') > -1
  const tenantSlug = getSlugTenantFromCareerHubUrl(resolvedUrl)

  // Case 1: Redirect user to onboarding page if tenants (list company of user) is empty
  if (
    user?.tenants?.data?.length === 0 &&
    !resolvedUrl.startsWith(configuration.path.onboarding)
  ) {
    return redirectTo({
      returnUrl: returnUrlIncludeExtension ? redirectPath : undefined,
      redirectPath: configuration.path.onboarding
    })
  }

  // Case 2: Redirect user to select tenants page if tenants (list company of user) is more than or equal two
  if (
    user?.tenants?.data?.length > 1 &&
    !cookies[SESSION_COOKIE_CURRENT_TENANT] &&
    !resolvedUrl.startsWith(configuration.path.selectCompany)
  ) {
    return redirectTo({
      returnUrl: returnUrlIncludeExtension ? redirectPath : undefined,
      redirectPath: configuration.path.selectCompany
    })
  }

  // if yes, then redirect to "redirectPath"
  return redirectTo({
    returnUrl: returnUrlIncludeExtension ? redirectPath : undefined,
    redirectPath:
      isCareerHubPath && tenantSlug
        ? configuration.path.careerHub.jobs(tenantSlug as string)
        : returnUrlIncludeExtension
        ? configuration.path.default
        : redirectPath
  })
}
function redirectTo({
  redirectPath,
  returnUrl
}: {
  redirectPath: string
  returnUrl?: string
}) {
  if (returnUrl) {
    const cleanReturnUrl = getPathFromReturnUrl(returnUrl)

    const queryParams = new URLSearchParams({
      returnUrl: cleanReturnUrl ?? configuration.path.default
    })

    // we build the sign in URL
    // appending the "returnUrl" query parameter so that we can redirect the user
    // straight to where they were headed and the "signOut" parameter
    // to force the client to sign the user out from the client SDK
    const destination = `${redirectPath}?${queryParams}`
    return {
      redirect: {
        permanent: false,
        destination
      }
    }
  } else {
    return {
      redirect: {
        permanent: false,
        destination: redirectPath
      }
    }
  }
}

export function getUserAuthentication(cookies: ICookies) {
  const authenticationToken = cookies[SESSION_COOKIE_USER]
  const tenantsToken = cookies[SESSION_COOKIE_TENANTS]
  const currentTenantToken = cookies[SESSION_COOKIE_CURRENT_TENANT]

  return getUserInfoByCookie({
    authCookie: authenticationToken,
    tenantsCookie: tenantsToken,
    currentTenantCookie: currentTenantToken
  })
}

function getAppPropsOptions(
  locale: string | undefined,
  options: Partial<typeof DEFAULT_OPTIONS>
) {
  const mergedOptions = { ...DEFAULT_OPTIONS, ...options }

  return {
    ...mergedOptions,
    locale: locale ?? mergedOptions.locale
  }
}

function getPathFromReturnUrl(returnUrl: string) {
  try {
    return new URL(returnUrl).pathname
  } catch (e) {
    return returnUrl.split('?')[0]
  }
}
