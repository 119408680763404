import { gql } from 'urql'

const QueryShowTenantMember = gql`
  query ($id: Int!) {
    showTenantMember(id: $id) {
      deviceRegistrations {
        id
        deviceToken
      }
      roles {
        id
        name
        code
      }
    }
  }
`

export default QueryShowTenantMember
