export const ICONS_SOCIAL = {
  facebook: '/img/img-social/facebook.svg',
  google: '/img/img-social/google.svg',
  linkedin: '/img/img-social/linkedin.svg',
  share: '/img/img-social/share.svg',
  googleplay: '/img/img-social/google_play.svg',
  googleplay_darkmode: '/img/img-social/google_play_darkmode.svg',
  appstore: '/img/img-social/app_store.svg',
  appstore_darkmode: '/img/img-social/app_store_darkmode.svg'
}
