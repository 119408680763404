import { useRouter } from 'next/router'
import useSWRMutation from 'swr/mutation'
import { HttpMethod, StringObject } from '~/core/@types/global'
import { useApiRequest } from '~/core/hooks/use-api'
import { DEFAULT_LOCALE } from '~/core/constants/env'

function useMiddlewareRequest({
  endpoint,
  method,
  headers
}: {
  endpoint: string
  method?: HttpMethod
  headers?: StringObject
}) {
  const router = useRouter()
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const fetcher = useApiRequest<void, object>()

  return useSWRMutation(endpoint, (path, { arg }: { arg: object }) => {
    return fetcher({
      path,
      body: arg,
      method,
      extraParams: {
        language: router.locale || DEFAULT_LOCALE,
        timezone: timeZone
      },
      headers
    })
  })
}

export default useMiddlewareRequest
