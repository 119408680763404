import { useRouter } from 'next/router'
import { FC } from 'react'
import { INotification } from '~/lib/features/notifications/types'
import {
  NOTIFICATION_CANDIDATE_APPLY,
  NOTIFICATION_CANDIDATE_PICK_INTERVIEW_TIME_SLOT,
  NOTIFICATION_CANDIDATE_REJECT_INTERVIEW_TIME_SLOT,
  NOTIFICATION_FULLY_APPROVED_REQUISITION,
  NOTIFICATION_OVERDUE_DATE_TASK,
  NOTIFICATION_PENDING_REQUISITION_REMINDER,
  NOTIFICATION_USER_ASSIGNED_TASK,
  NOTIFICATION_USER_CANCEL_INTERVIEW,
  NOTIFICATION_USER_CHANGE_COMPANY_OWNERSHIP,
  NOTIFICATION_USER_COMPLETE_TASK,
  NOTIFICATION_USER_DELETE_COMPANY,
  NOTIFICATION_USER_INTERVIEW_FEEDBACK,
  NOTIFICATION_USER_MENTION_APPLICANT_NOTE,
  NOTIFICATION_USER_MENTION_COMPANY_NOTE,
  NOTIFICATION_USER_MENTION_JOB_NOTE,
  NOTIFICATION_USER_MENTION_PROFILE_NOTE,
  NOTIFICATION_USER_REFER_CANDIDATE,
  NOTIFICATION_USER_REJECT_REQUISITION,
  NOTIFICATION_USER_RESCHEDULE_INTERVIEW,
  NOTIFICATION_USER_SETUP_INTERVIEW,
  NOTIFICATION_USER_SUBMIT_REQUISITION,
  NOTIFICATION_USER_UPDATE_JOB_STATUS
} from '~/lib/features/notifications/utilities/enum'
import CandidateApply from './CandidateApply'
import CandidatePickInterviewTimeSlot from './CandidatePickInterviewTimeSlot'
import CandidateRejectInterviewTimeSlot from './CandidateRejectInterviewTimeSlot'
import FullyApprovedRequisition from './FullyApprovedRequisition'
import OverdueDateTask from './OverdueDateTask'
import PendingRequisitionReminder from './PendingRequisitionReminder'
import UserAssignedTask from './UserAssignedTask'
import UserCancelInterview from './UserCancelInterview'
import UserChangeCompanyOwnership from './UserChangeCompanyOwnership'
import UserCompleteTask from './UserCompleteTask'
import UserDeleteCompany from './UserDeleteCompany'
import UserMentionApplicantNote from './UserMentionApplicantNote'
import UserMentionCompanyNote from './UserMentionCompanyNote'
import UserMentionJobNote from './UserMentionJobNote'
import UserMentionProfileNote from './UserMentionProfileNote'
import UserReferCandidate from './UserReferCandidate'
import UserRejectRequisition from './UserRejectRequisition'
import UserRescheduleInterview from './UserRescheduleInterview'
import UserSetupInterview from './UserSetupInterview'
import UserSubmitInterviewFeedback from './UserSubmitInterviewFeedback'
import UserSubmitRequisition from './UserSubmitRequisition'
import UserUpdateJobStatus from './UserUpdateJobStatus'

const NotificationItem: FC<{
  currentTenantName: string
  notification: INotification
  onClick: () => void
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({
  currentTenantName,
  notification,
  onClick,
  onDelete,
  isLoadingDeleted
}) => {
  const router = useRouter()
  const NotificationItemComponent =
    {
      [NOTIFICATION_USER_UPDATE_JOB_STATUS]: UserUpdateJobStatus,
      [NOTIFICATION_USER_MENTION_JOB_NOTE]: UserMentionJobNote,
      [NOTIFICATION_USER_MENTION_PROFILE_NOTE]: UserMentionProfileNote,
      [NOTIFICATION_USER_MENTION_APPLICANT_NOTE]: UserMentionApplicantNote,
      [NOTIFICATION_USER_MENTION_COMPANY_NOTE]: UserMentionCompanyNote,
      [NOTIFICATION_USER_ASSIGNED_TASK]: UserAssignedTask,
      [NOTIFICATION_USER_COMPLETE_TASK]: UserCompleteTask,
      [NOTIFICATION_OVERDUE_DATE_TASK]: OverdueDateTask,
      [NOTIFICATION_CANDIDATE_APPLY]: CandidateApply,
      [NOTIFICATION_USER_SETUP_INTERVIEW]: UserSetupInterview,
      [NOTIFICATION_USER_RESCHEDULE_INTERVIEW]: UserRescheduleInterview,
      [NOTIFICATION_USER_CANCEL_INTERVIEW]: UserCancelInterview,
      [NOTIFICATION_CANDIDATE_PICK_INTERVIEW_TIME_SLOT]:
        CandidatePickInterviewTimeSlot,
      [NOTIFICATION_CANDIDATE_REJECT_INTERVIEW_TIME_SLOT]:
        CandidateRejectInterviewTimeSlot,
      [NOTIFICATION_USER_REFER_CANDIDATE]: UserReferCandidate,
      [NOTIFICATION_USER_INTERVIEW_FEEDBACK]: UserSubmitInterviewFeedback,
      [NOTIFICATION_USER_SUBMIT_REQUISITION]: UserSubmitRequisition,
      [NOTIFICATION_FULLY_APPROVED_REQUISITION]: FullyApprovedRequisition,
      [NOTIFICATION_USER_REJECT_REQUISITION]: UserRejectRequisition,
      [NOTIFICATION_PENDING_REQUISITION_REMINDER]: PendingRequisitionReminder,
      [NOTIFICATION_USER_DELETE_COMPANY]: UserDeleteCompany,
      [NOTIFICATION_USER_CHANGE_COMPANY_OWNERSHIP]: UserChangeCompanyOwnership
    }[notification?.actionType] || (() => <div>Unsupported Log</div>)

  return (
    <NotificationItemComponent
      router={router}
      currentTenantName={currentTenantName}
      notification={notification}
      onClick={onClick}
      onDelete={onDelete}
      isLoadingDeleted={isLoadingDeleted}
    />
  )
}

export default NotificationItem
