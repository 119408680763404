'use client'

import * as icons from 'lucide-react'
import { FC, ReactNode } from 'react'

interface IconWrapperProps {
  name?: LucideIconName
  color?: string
  size?: number
  className?: string
  fill?: string
}
export type LucideIconName = keyof typeof icons

const IconWrapper: FC<IconWrapperProps> = ({
  name = 'X',
  size = 20,
  className = 'text-gray-600 dark:text-gray-400',
  ...props
}) => {
  const Icon = icons[name] as icons.LucideIcon
  return Icon ? <Icon size={size} className={className} {...props} /> : null
}

export type { IconWrapperProps }

export default IconWrapper
