import { gql } from 'urql'
import { number } from 'zod'

export type TenantPlanShow = {
  id: string
  name: string
  description: string
  status: 'trial' | 'paid' | 'expired' | 'free'
  statusDescription: string
  expiredAt: string
  remainingTrialDays: number
  planLimitExceeded: {
    tenant_member: boolean
    candidate: boolean
    job: boolean
  }
  tenantPlan: {
    name: string
    defaultTrial: boolean
    defaultTrialDays: number
    noOfUsers: number
    noOfCandidates: number
    noOfJobs: number
    planFeatures: { key: string; enabled: boolean; unlocked: boolean }[]
  }
}
const QueryTenantPlan = gql<
  {
    tenantPlanShow: TenantPlanShow
  },
  {}
>`
  query {
    tenantPlanShow {
      id
      name
      description
      status
      statusDescription
      expiredAt
      remainingTrialDays
      planLimitExceeded
      tenantPlan {
        name
        defaultTrial
        defaultTrialDays
        noOfUsers
        noOfCandidates
        noOfJobs
        planFeatures {
          key
          enabled
          unlocked
        }
      }
    }
  }
`

export default QueryTenantPlan
