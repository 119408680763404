import Gleap from 'gleap'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import configuration from '~/configuration'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import useSecuritySetting from '~/lib/features/settings/security/hooks/use-security-setting'
import { useSubmitSignOut } from '~/lib/features/sign-out/hooks/use-submit-sign-out'
import useBoundStore from '~/lib/store'
import useSubscriptionPlan from '../Subscription/useSubscriptionPlan'

export const PAGES_NOT_FETCH_API: string[] = [
  configuration.path.authError,
  configuration.path.careers.list,
  configuration.path.contactUs,
  configuration.path.error404,
  configuration.path.error500,
  configuration.path.errorAccessDenied,
  configuration.path.extension.list,
  configuration.path.login,
  configuration.path.termsOfUse,
  configuration.path.register,
  configuration.path.onboarding,
  configuration.path.verifyEmail,
  configuration.path.tenantBlocked,
  configuration.path.privacyPolicy,
  configuration.path.selectCompany,
  configuration.path.errorExpiredPlan,
  configuration.path.settings.plans_expired
]

const IdleTimerCount = ({
  onIdle,
  timeout = 10_000,
  throttle = 500,
  crossTab = true,
  leaderElection = true,
  syncTimers = 200
}: {
  onIdle: () => void
  timeout: number
  throttle: number
  crossTab: boolean
  leaderElection: boolean
  syncTimers: number
}) => {
  useIdleTimer({
    onIdle,
    timeout,
    throttle,
    crossTab,
    leaderElection,
    syncTimers
  })

  return null
}

const IdleTimerSettingWrapper = () => {
  const router = useRouter()
  const { user } = useBoundStore()
  const {
    isFeatureEnabled,
    isUnLockFeature,
    data: dataPlan
  } = useSubscriptionPlan()
  const { handleSignOut } = useSubmitSignOut()

  const { securitySettingShow, fetchSecuritySetting } = useSecuritySetting({
    shouldPause: true
  })
  const [config, setConfig] = useState({
    start: false,
    timeout: 10_000,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200
  })

  const [isFirstLoading, setFirstLoading] = useState(true)
  useEffect(() => {
    if (
      !PAGES_NOT_FETCH_API.some((url) => router?.pathname.startsWith(url)) &&
      user?.id
    ) {
      if (
        isFeatureEnabled(PLAN_FEATURE_KEYS.security_setting) &&
        isUnLockFeature(PLAN_FEATURE_KEYS.security_setting) &&
        isFirstLoading === true
      ) {
        setFirstLoading(false)
        fetchSecuritySetting()
      }
    }
  }, [user, dataPlan])

  useEffect(() => {
    const idleTime = Number(securitySettingShow?.idleTime)
    if (idleTime > 0) {
      setConfig({
        start: true,
        timeout: 1000 * 60 * idleTime,
        throttle: 500,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200
      })
    }
  }, [securitySettingShow])

  const signOut = useCallback(async () => {
    await handleSignOut()
    if (Gleap && Gleap.getInstance().initialized === true) {
      Gleap.clearIdentity()
    }
  }, [])

  if (config.start === false) return null

  return <IdleTimerCount {...config} onIdle={signOut} />
}

export default IdleTimerSettingWrapper
