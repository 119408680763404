import { StateCreator } from 'zustand'
import { IRole } from '~/core/@types/global'
import { LayoutSlice } from './layout-slice'
import { ToastSlice } from './toast-slice'

export interface DiscardChangeSlice {
  isFormDiscardDirty: boolean
  setIsFormDiscardDirty: (value: boolean) => void
}

export const createDiscardChangeSlice: StateCreator<
  LayoutSlice & ToastSlice & DiscardChangeSlice,
  [],
  [],
  DiscardChangeSlice
> = (set: Function) => ({
  isFormDiscardDirty: false,
  setIsFormDiscardDirty: (value: boolean) => set({ isFormDiscardDirty: value })
})
