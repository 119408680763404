import { StateCreator } from 'zustand'
import { ToastSlice } from './toast-slice'
import { UserSlice } from './user-slice'

export interface LayoutSlice {
  collapseSidebar: boolean
  toggleCollapse: () => void
  hideCollapse: () => void
  showCollapse: () => void
}

export const createLayoutSlice: StateCreator<
  UserSlice & ToastSlice & LayoutSlice,
  [],
  [],
  LayoutSlice
> = (set: Function) => ({
  collapseSidebar: false,
  toggleCollapse: () =>
    set((state: { collapseSidebar: boolean }) => ({
      collapseSidebar: !state.collapseSidebar
    })),
  hideCollapse: () => set(() => ({ collapseSidebar: false })),
  showCollapse: () => set(() => ({ collapseSidebar: true }))
})
