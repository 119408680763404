import {
  AUTH0_CLIENT_ID,
  AUTH0_CLIENT_ISSUER,
  AUTH0_CLIENT_SECRET,
  AZURE_AD_CLIENT_ID,
  AZURE_AD_CLIENT_SECRET,
  AZURE_AD_TENANT_ID,
  EMULATOR,
  FACEBOOK_CLIENT_ID,
  FACEBOOK_CLIENT_SECRET,
  GLEAP_API_KEY,
  GOOGLE_API_KEY,
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
  GOOGLE_RECAPTCHA_API_KEY,
  GOOGLE_RECAPTCHA_SECRET_API_KEY,
  LINKEDIN_CLIENT_ID,
  LINKEDIN_CLIENT_SECRET,
  TWITTER_CLIENT_ID,
  TWITTER_CLIENT_SECRET
} from '../core/constants/env'

const keysConfiguration = {
  linkedin: {
    id: LINKEDIN_CLIENT_ID,
    secret: LINKEDIN_CLIENT_SECRET
  },
  facebook: {
    id: FACEBOOK_CLIENT_ID,
    secret: FACEBOOK_CLIENT_SECRET
  },
  twitter: {
    id: TWITTER_CLIENT_ID,
    secret: TWITTER_CLIENT_SECRET
  },
  google: {
    id: GOOGLE_CLIENT_ID,
    secret: GOOGLE_CLIENT_SECRET
  },
  microsoft: {
    id: AZURE_AD_CLIENT_ID,
    secret: AZURE_AD_CLIENT_SECRET,
    tenantId: AZURE_AD_TENANT_ID
  },
  auth0: {
    id: AUTH0_CLIENT_ID,
    secret: AUTH0_CLIENT_SECRET,
    issuer: AUTH0_CLIENT_ISSUER
  },
  emulator: EMULATOR,
  googleRecaptchaSecret: GOOGLE_RECAPTCHA_SECRET_API_KEY,
  googleRecaptcha: GOOGLE_RECAPTCHA_API_KEY,
  googleApiKey: GOOGLE_API_KEY,
  gleapApiKey: GLEAP_API_KEY
}

export default keysConfiguration
