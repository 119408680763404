import { ISelectOption } from '../@types/global'
import { KeyboardEventHandler } from 'react'

const timeArrayMode: Array<string> = ['AM', 'PM']
const timeArrayHours: Array<number> = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
const timeArrayMinutes: Array<number> = [0, 15, 30, 45]
const to2Digit = (num: number) => ('0' + num).slice(-2)

export const selectTimeList = timeArrayMode.reduce(
  (list: Array<ISelectOption>, A: string) => {
    return [
      ...list,
      ...timeArrayHours.reduce((listH: Array<ISelectOption>, hour: number) => {
        return [
          ...listH,
          ...timeArrayMinutes.map((minute: number) => ({
            value: `${to2Digit(hour)}:${to2Digit(minute)} ${A}`,
            label: `${to2Digit(hour)}:${to2Digit(minute)} ${A}`
          }))
        ]
      }, [])
    ]
  },
  []
)

export const delayMilliseconds = (ms: number) =>
  new Promise((res) => setTimeout(res, ms))

export const removeAttributesFromServer = (
  source: Array<{ attributes: { id: string; name: string } }>
) => {
  return source.map((item) => item.attributes)
}

export function capitalizeFirstLetter(stringValue: string) {
  if (!stringValue || stringValue === '') return stringValue
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1)
}

export const mappingQueryFromGraphQL = (
  source: Array<{ id: string; name: string }>
) => {
  return source.map((item) => ({
    value: String(item.id),
    supportingObj: {
      name: capitalizeFirstLetter(String(item.name))
    }
  }))
}

export const getDisabledSelectedCondition = ({
  isDisabled = false,
  isSelected = false
}: {
  isDisabled?: boolean
  isSelected?: boolean
}) => {
  if (isDisabled == false && isSelected == false) return 'noDisabledSelected'
  if (isDisabled == false && isSelected == true) return 'noDisabledWithSelected'
  if (isDisabled == true && isSelected == false) return 'disabledNoSelect'
  if (isDisabled == true && isSelected == true) return 'disabledWithSelect'

  return 'noDisabledSelected'
}

export function isHTML(content: string): boolean {
  if (content && content.match('</[^>]+>')) {
    return true
  }
  return false
}

export const trimFirstContentBreakLine = (content?: string) => {
  if (!content) return ''
  if (content && content === '<p><br></p>')
    return content.replace('<p><br></p>', '')
  if (content && content === '<p></p>') return content.replace('<p></p>', '')
  return content
}

export function removeAccents(str: string): string {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export function checkCvFileType(fileName?: string, type?: string): boolean {
  if (!fileName) return false
  return !!fileName.toLowerCase().match(`\\.${type}`)
}

export function getMonths() {
  let i = 1
  const endInt = 12
  const temp = []

  while (i <= endInt) {
    temp.push({
      value: i.toString(),
      supportingObj: {
        name: i.toString()
      }
    })
    i++
  }

  return temp
}

export function getYears() {
  let y = new Date().getFullYear()
  const tempYear = []

  while (y >= 1960) {
    tempYear.push({
      value: y.toString(),
      supportingObj: {
        name: y.toString()
      }
    })
    y--
  }

  return tempYear
}

export function getFoundedYears() {
  let y = new Date().getFullYear()
  const tempYear = []

  while (y >= 1800) {
    tempYear.push({
      value: y.toString(),
      supportingObj: {
        name: y.toString()
      }
    })
    y--
  }

  return tempYear
}

export const regexEmailValidation =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i

export const regexEmailDomainValidation =
  /^((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i

export const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const formatAddressLocation = ({
  location
}: {
  location: {
    address?: string
    name?: string
    city?: string
    state?: string
    country?: string
  }
}) => {
  const arr = [
    location.name || location.address,
    location.city,
    location.state,
    location.country
  ]
  const filterEmpty = arr.filter((item) => item)

  return filterEmpty.map((item) => item).join(', ')
}

export const fetchAndDownloadFile = ({
  file,
  name
}: {
  file: string
  name?: string
}) => {
  if (!file) return

  return fetch(file).then((response) => {
    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name || file)
      document.body.appendChild(link)
      link.click()
      link.remove()
    })
  })
}

export const trimAllEmptySpace = (text: string) => {
  if (!text) return ''
  return text.replace(/^\s+|\s+$/gm, '')
}

export const removeHTMLTags = (content: string): string => {
  return content.replace(/(<([^>]+)>)/gi, '')
}

export const truncateTextWithDot = (text: string, textLength = 25) => {
  return text?.length > textLength ? `${text.slice(0, textLength)}...` : text
}

export const convertStringArrayToArray = (message: string) => {
  if (!message) return []
  return typeof message === 'string' && message[0] === '['
    ? JSON.parse(message.replace(/\'/g, ''))
    : []
}

export const getTimeZone = (date: string) => {
  const tz = date.match(/[-\+][0-9][0-9]:[0-9][0-9]/)?.[0]
  return tz?.includes('00:00') ? '' : tz
}

export const sendChromeRuntimeMessage = (paramsCookie: unknown) => {
  const params = new URL(String(window.location)).searchParams
  const returnUrl = params.get('returnUrl')

  if (returnUrl && returnUrl.startsWith('/ext')) {
    const myInterval = setInterval(myTimer, 1000)

    function myTimer() {
      //@ts-ignore
      if (chrome?.runtime?.sendMessage) {
        clearInterval(myInterval)

        //@ts-ignore
        chrome.runtime.sendMessage(
          extensionId,
          { callbackHireForce: { cookies: paramsCookie, extensionId } },
          function () {
            return Promise.resolve()
          }
        )
      }
    }

    const splitParam = returnUrl.split('/')
    const extensionId = splitParam.pop()
  }

  return Promise.resolve()
}

export const reorder = (list = [] as any, startIndex = 0, endIndex = 0) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result as any
}

export function nonAccentVietnamese(str: string) {
  if (!str || typeof str !== 'string') {
    return ''
  }
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A')
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E')
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I')
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O')
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U')
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y')
  str = str.replace(/Đ/g, 'D')
  return str
}

export const removeEndSplash = (link: string) => link?.replace(/\/$/g, '')

export const scrollToElement = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }
}

export const removeTags = (string: string) => {
  return string
    .replace(/<[^>]*>/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .replaceAll('&nbsp;', ' ')
    .replace(/[0-9]([0-9])?./g, '')
    .trim()
}

export const checkIfArrayStringsContainsObjectKeys = (
  arrayKeys: Array<string>,
  objectName: any
) => {
  for (let i = 0; i < arrayKeys.length; i++) {
    const keyName = arrayKeys[i]
    if (Object.keys(objectName).some((key) => key === keyName)) {
      return true
    }
  }
  return false
}

export const createPreventPositiveChange = (
  onChange: (value: string | number) => void
) => ({
  onKeyDown: ((event) => {
    if (event.key === '-' || event.key === 'e') {
      event.preventDefault()
    }
  }) as KeyboardEventHandler<HTMLInputElement>,
  onChange: (value: string | number) => {
    if (/^\d*$/.test(value.toString())) {
      onChange(value)
    }
  }
})
export const regexInputUrlValidation = /^[a-zA-Z0-9-_]+$/

export const isInCareerHubApp = (path?: string) =>
  (path || '').includes('careerhub')
