import { useCallback, useEffect, useMemo, useState } from 'react'
import configuration from '~/configuration'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import { useInfinityGraphPage } from '../../jobs/hooks/use-infinity-graph-page'
import MutationDeleteNotification from '../graphql/mutation-delete-notification'
import MutationUpdateNotificationMarkAsRead from '../graphql/mutation-update-notification-mark-as-read'
import QueryNotificationsList from '../graphql/query-notifications-list'
import { INotificationManagementFilter } from '../types'
import { trimObjectProps } from '../utilities/common'

export const TABS_CACHE_KEYS: {
  [key: string]: {
    [key: string]: string
  }
} = {
  default: {
    default: 'notification-management-default',
    UnRead: 'notification-management-un-read'
  }
}

const useNotificationsManagement = () => {
  const [filterValue, onChangeFilter] =
    useState<INotificationManagementFilter>()
  const {
    trigger: updateNotificationMarkAsRead,
    isLoading: isLoadingUpdateNotificationMarkAsRead
  } = useSubmitCommon(MutationUpdateNotificationMarkAsRead)
  const {
    trigger: deleteNotification,
    isLoading: isLoadingDeleteNotification
  } = useSubmitCommon(MutationDeleteNotification)

  const queryKey = useMemo(
    () => TABS_CACHE_KEYS.default?.[filterValue?.filterBy || 'default'],
    [filterValue?.filterBy]
  )

  const notificationPaging = useInfinityGraphPage({
    queryDocumentNote: QueryNotificationsList,
    getVariable: useCallback(
      (page) => {
        const { filterBy } = filterValue || {}
        return trimObjectProps({
          limit: configuration.defaultPageSize,
          page,
          ...(filterBy ? { unread: true } : {})
        })
      },
      [filterValue]
    ),
    getPageAttribute: (_lastGroup, groups) => ({
      totalCount: _lastGroup?.notificationsList?.metadata?.totalCount,
      pageLength: groups?.[0]?.notificationsList?.collection?.length
    }),
    queryKey: [queryKey]
  })

  useEffect(() => {
    notificationPaging.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  return {
    notificationPaging,
    queryKey,
    filterControl: useMemo(
      () => ({ value: filterValue, onChange: onChangeFilter }),
      [filterValue]
    ),
    action: {
      update: {
        notificationMarkAsRead: (args: { id?: string; all?: boolean }) =>
          updateNotificationMarkAsRead(args),
        isLoadingNotificationMarkAsRead: isLoadingUpdateNotificationMarkAsRead
      },
      delete: {
        notification: (args: { id?: string; all?: boolean }) =>
          deleteNotification(args),
        isLoadingNotification: isLoadingDeleteNotification
      }
    }
  }
}

export default useNotificationsManagement
