const MutationUpdateNotificationMarkAsRead = `
  mutation (
    $id: String,
    $all: Boolean
  ) {
    notificationsMarkAsRead(
      input: {
        id: $id,
        all: $all
      }
    ) {
      success
    }
  }
`

export default MutationUpdateNotificationMarkAsRead
