import Gleap from 'gleap'
import { NextRouter } from 'next/router'
import { destroyCookie, parseCookies } from 'nookies'
// eslint-disable-next-line import/no-restricted-paths
import { getUserAuthentication } from '~/lib/next/auth-utilities'
import configuration from '../../configuration'
import { IFormAction, IToast } from '../@types/global'
import {
  COOKIE_PATH,
  SESSION_COOKIE_CURRENT_TENANT,
  SESSION_COOKIE_CURRENT_TENANT_EXT,
  SESSION_COOKIE_EXT,
  SESSION_COOKIE_IP,
  SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_ROLES,
  SESSION_COOKIE_ROLE_EXT,
  SESSION_COOKIE_SSO,
  SESSION_COOKIE_TENANTS,
  SESSION_COOKIE_USER,
  SESSION_CSRF_SECRET_COOKIE,
  SESSION_EXPIRES_AT_COOKIE_NAME
} from '../constants/cookies'
import { setSessionCookieClient } from '../middleware/save-session-cookie'

export const LIST_KEYS_TENANT_BLOCKED = [
  'BLOCKED_TENANT_ERROR',
  'BLOCKED_ALL_TENANT_ERROR'
]
export const LIST_KEYS_TENANT_EXPIRED = ['EXPIRED_TENANT_PLAN_ERROR']
/**
 * @description destroy session cookies to sign user out
 * @param res
 */
export function destroySessionCookiesClient() {
  const options = { path: COOKIE_PATH }

  if (Gleap && Gleap.getInstance().initialized === true) {
    Gleap.clearIdentity()
  }

  destroyCookie(null, SESSION_COOKIE_NAME, options)
  destroyCookie(null, SESSION_COOKIE_EXT, options)
  destroyCookie(null, SESSION_COOKIE_USER, options)
  destroyCookie(null, SESSION_EXPIRES_AT_COOKIE_NAME, options)
  destroyCookie(null, SESSION_COOKIE_ROLES, options)
  destroyCookie(null, SESSION_COOKIE_ROLE_EXT, options)
  destroyCookie(null, SESSION_COOKIE_TENANTS, options)
  destroyCookie(null, SESSION_COOKIE_CURRENT_TENANT, options)
  destroyCookie(null, SESSION_COOKIE_CURRENT_TENANT_EXT, options)
  destroyCookie(null, SESSION_CSRF_SECRET_COOKIE, options)
  destroyCookie(null, SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM, options)
  destroyCookie(null, SESSION_COOKIE_SSO, options)
  destroyCookie(null, SESSION_COOKIE_IP, options)
}

export const catchErrorFromGraphQL = ({
  error,
  page,
  callback,
  formAction,
  setToast,
  router,
  callbackHandleStatusError422,
  error404ShouldForceToNotFoundPage
}: {
  error: {
    graphQLErrors: Array<{
      message?: string
    }>
    networkError?: object
  }
  page?: string
  callback?: (errorText: string) => void
  formAction?: IFormAction
  setToast?: (state: IToast) => void
  router?: NextRouter
  callbackHandleStatusError422?: (
    keys: Array<{ field: string; message: string }>,
    blockedErrorCode?: string
  ) => void
  error404ShouldForceToNotFoundPage?: boolean
}) => {
  const { graphQLErrors, networkError } = error
  const cookies = parseCookies()

  const handleDirectHForCHub = ({
    HFUrl,
    CHubUrl
  }: {
    HFUrl: string
    CHubUrl: string
  }) => {
    if (router) {
      if (router.pathname.startsWith('/careerhub/') && !!router.query?.['slug'])
        router.push(CHubUrl)
      else router.push(HFUrl)
    } else {
      if (window.location.pathname.startsWith('/careerhub/')) {
        const slug = window.location.pathname.split('/')?.[2]
        window.location.href = CHubUrl
      } else window.location.href = HFUrl
    }
  }

  if (networkError) {
    if (setToast) {
      setToast({
        open: true,
        type: 'error',
        title: 'Network Error'
      })
    }

    return null
  }

  if (graphQLErrors.length === 1) {
    const parseErrors = JSON.parse(JSON.stringify(graphQLErrors[0]))
    const keys =
      parseErrors?.message && typeof parseErrors?.message === 'string'
        ? parseErrors?.extensions?.errors
        : parseErrors?.message?.extensions?.errors
    const statusCode =
      parseErrors?.message && typeof parseErrors?.message === 'string'
        ? parseErrors?.extensions?.http_code
        : parseErrors?.message?.extensions?.http_code

    let blockedErrorCode =
      parseErrors?.extensions?.error_code ||
      parseErrors?.message?.extensions?.error_code

    if (
      blockedErrorCode &&
      LIST_KEYS_TENANT_BLOCKED.includes(blockedErrorCode) &&
      [401, 403].includes(statusCode)
    ) {
      setSessionCookieClient(
        SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM,
        JSON.stringify(true).toString(),
        Number(cookies[SESSION_EXPIRES_AT_COOKIE_NAME])
      )
      if (router) {
        router.push(configuration.path.tenantBlocked)
      } else {
        window.location.href = configuration.path.tenantBlocked
      }
      return null
    }
    if (
      blockedErrorCode &&
      LIST_KEYS_TENANT_EXPIRED.includes(blockedErrorCode) &&
      [401, 403].includes(statusCode)
    ) {
      const user = getUserAuthentication(cookies)
      const expiredPage = user?.ownTenant
        ? configuration.path.settings.plans_expired
        : configuration.path.errorExpiredPlan
      if (router) {
        router.push(expiredPage)
      } else {
        window.location.href = expiredPage
      }
      return null
    }

    if (statusCode === 401) {
      destroySessionCookiesClient()
      if (setToast) {
        setToast({
          open: true,
          type: 'info',
          title: 'You need to login again.'
        })
      }

      return setTimeout(() => {
        if (window.location.pathname.includes('/careerhub')) {
          const slug = window.location.pathname.split('/')?.[2]
          window.location.href = configuration.path.careerHub.login(slug)
        } else {
          window.location.href = configuration.path.login
        }
      }, 1500)
    }

    if (statusCode === 403) {
      if (setToast) {
        setToast({
          open: true,
          type: 'error',
          title:
            'You do not have permission to access the requested page. Please contact your system administrator for further assistance.'
        })
      }
      handleDirectHForCHub({
        HFUrl: configuration.path.errorAccessDenied,
        CHubUrl: configuration.path.careerHub.errorAccessDenied(
          String(
            router
              ? router.query?.['slug']
              : window.location.pathname.split('/')?.[2]
          )
        )
      })
      // if (router) {
      //   //check is in CHub APP or not
      //   if (
      //     router.pathname.startsWith('/careerhub/') &&
      //     !!router.query?.['slug']
      //   )
      //     router.push(
      //       configuration.path.careerHub?.errorAccessDenied(
      //         String(router.query?.['slug'])
      //       )
      //     )
      //   else router.push(configuration.path.errorAccessDenied)
      // } else {
      //   //check is in CHub APP or not
      //   if (window.location.pathname.startsWith('/careerhub/')) {
      //     const slug = window.location.pathname.split('/')?.[2]
      //     window.location.href =
      //       configuration.path.careerHub?.errorAccessDenied(String(slug))
      //   } else window.location.href = configuration.path.errorAccessDenied
      // }
    }

    if (statusCode === 404) {
      if (
        parseErrors?.message?.extensions?.error_code === 'RESOURCE_NOT_FOUND'
      ) {
        handleDirectHForCHub({
          HFUrl: configuration.path.error404,
          CHubUrl: configuration.path.careerHub.error404(
            String(
              router
                ? router.query?.['slug']
                : window.location.pathname.split('/')?.[2]
            )
          )
        })
        // if (router) {
        //   router.push(configuration.path.error404)
        // } else {
        //   window.location.href = configuration.path.error404
        // }
      } else {
        if (error404ShouldForceToNotFoundPage) {
          handleDirectHForCHub({
            HFUrl: configuration.path.error404,
            CHubUrl: configuration.path.careerHub.error404(
              String(
                router
                  ? router.query?.['slug']
                  : window.location.pathname.split('/')?.[2]
              )
            )
          })
          // if (router) {
          //   router.push(configuration.path.error404)
          // } else {
          //   window.location.href = configuration.path.error404
          // }
        } else {
          if (setToast) {
            setToast({
              open: true,
              type: 'error',
              title: parseErrors?.message?.extensions?.error_code
            })
          }
        }
      }
    }

    if (statusCode === 500) {
      if (setToast) {
        setToast({
          open: true,
          type: 'error',
          title: 'Network Error'
        })
      }
    }

    if (statusCode === 422) {
      if (callbackHandleStatusError422) {
        callbackHandleStatusError422(keys, blockedErrorCode)
        return
      }

      keys.forEach((session: { field: string; message: string }) => {
        if (
          session.field &&
          formAction &&
          Object.keys(formAction).length &&
          formAction.control._fields[session.field]
        ) {
          formAction.setError(session.field, {
            type: 'custom',
            message: String(session.message)
          })
        } else if (setToast) {
          setToast({
            open: true,
            type: 'error',
            title: session.message
          })
        }
      })
    }

    if (statusCode === 400) {
      if (setToast && !!error?.graphQLErrors?.[0]?.message) {
        setToast({
          open: true,
          type: 'error',
          title: error?.graphQLErrors?.[0]?.message
        })
      }
    }
  }

  return null
}

export const catchErrorFromRequest = ({
  error,
  formAction,
  setToast,
  callbackHandleStatusError422
}: {
  error?: unknown | { errors: Array<{ extensions: object }> }
  formAction?: IFormAction
  setToast?: (state: IToast) => void
  callbackHandleStatusError422?: (
    keys: Array<{ field: string; message: string }>,
    blockedErrorCode?: string
  ) => void
}) => {
  if (error && Object.keys(error).length) {
    // @ts-expect-error
    const errors = error?.errors

    if (errors?.length && formAction && Object.keys(formAction).length) {
      const parseErrors = JSON.parse(JSON.stringify(errors[0]))
      const keys = parseErrors?.extensions?.errors || []
      let blockedErrorCode =
        parseErrors?.extensions?.error_code ||
        parseErrors?.message?.extensions?.error_code

      if (callbackHandleStatusError422) {
        callbackHandleStatusError422(keys, blockedErrorCode)
        return
      }

      keys.forEach((session: { field: string; message: string }) => {
        formAction.setError(session.field, {
          type: 'custom',
          message: [session.message]
        })
      })
    } else {
      if (setToast) {
        let message = JSON.stringify(error)
        if (errors.length) {
          const parseErrors = JSON.parse(JSON.stringify(errors[0]))
          if (parseErrors?.extensions?.error_code === 'RECORD_NOT_FOUND') {
            message = 'Record not found'
          }
        }
        setToast({
          open: true,
          type: 'error',
          title: message
        })
      } else {
        console.error(error)
      }
    }
  }

  return null
}
