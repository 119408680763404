import { gql } from 'urql'

const QueryTenantFeatureSetting = gql<{
  tenantFeatureSetting: {
    key: string
    values: {
      enabling_requisition: boolean
    }
    permissions: ['manage_requisition', 'request_requisition']
  }[]
}>`
  query {
    tenantFeatureSetting
  }
`

export default QueryTenantFeatureSetting
