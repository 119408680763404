import { StateCreator } from 'zustand'

export interface MyListSlice {
  refetchMyList: boolean
  ignoreListRefetch?: Array<string>
  setRefetchMyList: (
    selected: boolean,
    ignoreListRefetch?: Array<string>
  ) => void
}

export const createMyListSlice: StateCreator<
  MyListSlice,
  [],
  [],
  MyListSlice
> = (set: Function) => ({
  refetchMyList: false,
  ignoreListRefetch: [],
  setRefetchMyList: (selected: boolean, ignoreListRefetch?: Array<string>) =>
    set({ refetchMyList: selected, ignoreListRefetch: ignoreListRefetch || [] })
})
