import { create } from 'zustand'

interface NotificationsSlice {
  openNotificationDrawer: boolean
  setOpenNotificationDrawer: (open: boolean) => void
  notificationTotalUnreadCount: number
  setNotificationTotalUnreadCount: (count: number) => void
}

const useNotificationStore = create<NotificationsSlice>((set) => ({
  openNotificationDrawer: false,
  setOpenNotificationDrawer: (open: boolean) =>
    set({ openNotificationDrawer: open }),
  notificationTotalUnreadCount: 0,
  setNotificationTotalUnreadCount: (count: number) =>
    set({ notificationTotalUnreadCount: count })
}))

export default useNotificationStore
