import { NextRouter } from 'next/router'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import configuration from '~/configuration'
import { ISelectOption } from '~/core/ui/Select'
import { TypographyText } from '~/core/ui/Text'
import {
  INotification,
  INotificationJob
} from '~/lib/features/notifications/types'
import NotificationItemLayout from './NotificationItemLayout'

const UserUpdateJobStatus: FC<{
  router: NextRouter
  notification: INotification
  onClick: () => void
  onDelete: () => void
  isLoadingDeleted: boolean
}> = ({ router, notification, onClick, onDelete, isLoadingDeleted }) => {
  const { i18n } = useTranslation()
  const jobStatus = useEnumsData({
    enumType: 'JobStatus',
    locale: i18n.language
  })
  const propertiesRelated = notification?.payload as INotificationJob

  return (
    <NotificationItemLayout
      onDelete={onDelete}
      isLoadingDeleted={isLoadingDeleted}
      user={notification.user}
      actionType={notification.actionType}
      readAt={notification.readAt}
      createdAt={notification.createdAt}>
      {({ time }) => (
        <TypographyText
          className="cursor-pointer text-sm text-gray-700"
          onClick={() => {
            router.push(configuration.path.jobs.detail(propertiesRelated.jobId))
            onClick && onClick()
          }}>
          <Trans
            i18nKey="activity:notification:userUpdateJobStatus"
            values={{
              userName: notification?.user?.fullName,
              jobTitle: propertiesRelated?.jobTitle,
              oldJobStatus: jobStatus.filter(
                (item: ISelectOption) =>
                  item.value === propertiesRelated?.fromStatus
              )?.[0]?.supportingObj?.name,
              newJobStatus: jobStatus.filter(
                (item: ISelectOption) =>
                  item.value === propertiesRelated?.toStatus
              )?.[0]?.supportingObj?.name
            }}>
            <span className="font-medium text-gray-900" />
          </Trans>
          {time}
        </TypographyText>
      )}
    </NotificationItemLayout>
  )
}

export default UserUpdateJobStatus
