import { StateCreator } from 'zustand'

export interface MyDeleteRefeshSlice {
  refetchMyDelete: boolean
  ignoreListRefetch?: Array<string>
  setRefetchMyDelete: (
    selected: boolean,
    ignoreListRefetch?: Array<string>
  ) => void
}

export const createMyDeleteSlice: StateCreator<
  MyDeleteRefeshSlice,
  [],
  [],
  MyDeleteRefeshSlice
> = (set: Function) => ({
  refetchMyDelete: false,
  ignoreListRefetch: [],
  setRefetchMyDelete: (selected: boolean, ignoreListRefetch?: Array<string>) =>
    set({
      refetchMyDelete: selected,
      ignoreListRefetch: ignoreListRefetch || []
    })
})
