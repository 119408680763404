import { FC } from 'react'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { isHTML } from '~/core/utilities/common'

const HTMLDisplay: FC<{
  content?: string
  className?: string
}> = ({ content, className = 'text-sm text-gray-900' }) => {
  if (!content) return null

  return (
    <>
      {isHTML(content) ? (
        <div
          className={cn(
            'prose max-w-full dark:prose-invert prose-p:!my-0',
            className
          )}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <TypographyText className={className}>{content}</TypographyText>
      )}
    </>
  )
}

export default HTMLDisplay
