import { gql } from 'urql'

const QueryTenantSecuritySettingShow = gql`
  query {
    tenantSecuritySettingShow {
      id
      values
    }
  }
`

export default QueryTenantSecuritySettingShow
