// Auth 3rd plugin
export const LINKEDIN_CLIENT_ID = process.env.LINKEDIN_CLIENT_ID || ''
export const LINKEDIN_CLIENT_SECRET = process.env.LINKEDIN_CLIENT_SECRET || ''
export const FACEBOOK_CLIENT_ID = process.env.FACEBOOK_CLIENT_ID || ''
export const FACEBOOK_CLIENT_SECRET = process.env.FACEBOOK_CLIENT_SECRET || ''
export const TWITTER_CLIENT_ID = process.env.TWITTER_CLIENT_ID || ''
export const TWITTER_CLIENT_SECRET = process.env.TWITTER_CLIENT_SECRET || ''
export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID || ''
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET || ''
export const AZURE_AD_CLIENT_ID = process.env.AZURE_AD_CLIENT_ID || ''
export const AZURE_AD_CLIENT_SECRET = process.env.AZURE_AD_CLIENT_SECRET || ''
export const AZURE_AD_TENANT_ID = process.env.AZURE_AD_TENANT_ID || ''
export const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID || ''
export const AUTH0_CLIENT_SECRET = process.env.AUTH0_CLIENT_SECRET || ''
export const AUTH0_CLIENT_ISSUER = process.env.AUTH0_CLIENT_ISSUER || ''

// Sentry
export const SENTRY_DSN = process.env.SENTRY_DSN || ''
export const PUBLIC_SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || ''

export const DEFAULT_LOCALE = process.env.DEFAULT_LOCALE || 'en'
export const NODE_ENV = process.env.NODE_ENV
export const APP_ENVIRONMENT =
  process.env.NEXT_PUBLIC_APP_ENVIRONMENT ?? 'development'
export const PUBLIC_API_URL = process.env.NEXT_PUBLIC_API_URL
export const PUBLIC_APP_URL = process.env.NEXT_PUBLIC_APP_URL
export const PUBLIC_APP_NAME = process.env.NEXT_PUBLIC_APP_NAME
export const PUBLIC_HELP_CENTER_URL = process.env.NEXT_PUBLIC_HELP_CENTER_URL

export const EMULATOR = process.env.NEXT_PUBLIC_EMULATOR || ''
export const GOOGLE_RECAPTCHA_SECRET_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SECRET_API_KEY || ''
export const GOOGLE_RECAPTCHA_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_API_KEY || ''
export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''

export const GLEAP_API_KEY = process.env.NEXT_PUBLIC_GLEAP_API_KEY || ''
export const PUBLIC_ENABLE_API_PROXY =
  process.env.NEXT_PUBLIC_ENABLE_API_PROXY || ''
export const PUBLIC_LANDING_PAGE_URL =
  process.env.NEXT_PUBLIC_LANDING_PAGE_URL || ''

export const ROLE_LIMITED_MEMBER =
  process.env.NEXT_PUBLIC_ROLE_INTERVIEWER ||
  process.env.NEXT_PUBLIC_ROLE_LIMITED_MEMBER ||
  ''
export const ROLE_ADMIN =
  process.env.NEXT_PUBLIC_ROLE_ADMINISTRATOR ||
  process.env.NEXT_PUBLIC_ROLE_ADMIN ||
  ''
export const ROLE_MEMBER =
  process.env.NEXT_PUBLIC_ROLE_RECRUITER ||
  process.env.NEXT_PUBLIC_ROLE_MEMBER ||
  ''

export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY
export const FIREBASE_PUSH_KEY = process.env.NEXT_PUBLIC_FIREBASE_PUSH_KEY
export const FIREBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN
export const FIREBASE_DATABASE_URL =
  process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL
export const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID
export const FIREBASE_STORAGE_BUCKET =
  process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID
export const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID
export const FIREBASE_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
