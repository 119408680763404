export const SESSION_COOKIE_NAME = 'session'
export const SESSION_COOKIE_EXT = 'sessionExt'
export const SESSION_COOKIE_USER = 'sessionUser'
export const SESSION_EXPIRES_AT_COOKIE_NAME = 'sessionExpiresAt'
export const SESSION_COOKIE_ROLES = 'sessionRoles'
export const SESSION_COOKIE_ROLE_EXT = 'sessionRoleExt'
export const SESSION_COOKIE_TENANTS = 'sessionTenants'
export const SESSION_COOKIE_CURRENT_TENANT = 'sessionCurrentTenant'
export const SESSION_COOKIE_CURRENT_TENANT_EXT = 'sessionCurrentTenantExt'
export const SESSION_CSRF_SECRET_COOKIE = `csrfSecret`
export const COOKIE_PATH = '/'
export const SESSION_COOKIE_IS_DISABLE_SIDEBAR_ITEM =
  'sessionDisableSidebarItem'
export const SESSION_COOKIE_SSO = 'sessionSSO'
export const SESSION_COOKIE_IP = 'sessionIP'

// Version to force logout - How to use: Increase or downgrade version
export const SESSION_VERSION = 'v2.2.0'
