import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import withTranslateProvider from 'src/hoc/with-translate-provider'
import { PUBLIC_LANDING_PAGE_URL } from '~/core/constants/env'
import { Button } from '~/core/ui/Button'

const MobileNotSupportView = () => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <div className="flex h-screen flex-col items-center justify-center p-4">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="113"
          height="112"
          viewBox="0 0 113 112"
          fill="none">
          <circle opacity="0.5" cx="56.5" cy="56" r="56" fill="#EDEFFF" />
          <circle opacity="0.6" cx="56.5" cy="56" r="44" fill="#DFE3FF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.5 38C46.5589 38 38.5 46.0589 38.5 56C38.5 65.9411 46.5589 74 56.5 74C66.4411 74 74.5 65.9411 74.5 56C74.5 46.0589 66.4411 38 56.5 38ZM34.5 56C34.5 43.8497 44.3497 34 56.5 34C68.6503 34 78.5 43.8497 78.5 56C78.5 68.1503 68.6503 78 56.5 78C44.3497 78 34.5 68.1503 34.5 56ZM56.5 54C57.6046 54 58.5 54.8954 58.5 56V64C58.5 65.1046 57.6046 66 56.5 66C55.3954 66 54.5 65.1046 54.5 64V56C54.5 54.8954 55.3954 54 56.5 54ZM56.5 46C55.3954 46 54.5 46.8954 54.5 48C54.5 49.1046 55.3954 50 56.5 50H56.52C57.6246 50 58.52 49.1046 58.52 48C58.52 46.8954 57.6246 46 56.52 46H56.5Z"
            fill="#5D5AF9"
          />
        </svg>
      </div>
      <p className="mb-5 mt-11 text-center text-2xl font-semibold text-gray-900">
        {t('common:mobile:labelMobileVersionUnavailable')}
      </p>
      <p className="mb-11 text-center text-base text-gray-900">
        {t('common:mobile:descriptionMobileVersionUnavailable')}
      </p>
      <Button
        size="lg"
        className="w-full"
        label={`${t('button:iGotIt')}`}
        onClick={() => router.push(PUBLIC_LANDING_PAGE_URL || '')}
      />
    </div>
  )
}

export default withTranslateProvider(MobileNotSupportView)
