import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactElement, useMemo } from 'react'

const withQueryClientProvider = <T extends object>(
  Component: (props: T) => ReactElement | null
) => {
  const WithQueryClient = (props: T) => {
    const queryClient = useMemo(() => new QueryClient(), [])
    return (
      <QueryClientProvider client={queryClient}>
        <Component {...props} />
      </QueryClientProvider>
    )
  }
  return WithQueryClient
}
export default withQueryClientProvider
