export const trimObjectProps = <
  T extends {
    [key: string]: number | number[] | string | string[] | boolean | undefined
  }
>(
  props: T
): Partial<T> =>
  Object.keys(props).reduce(
    (result, key) => ({
      ...result,
      ...(props[key] ? { [key]: props[key] } : {})
    }),
    {}
  )
